// node_module
import React from "react";
import Helmet from "react-helmet";
import {withRouter} from "react-router-dom";
import cogoToast from "cogo-toast";
import WOW from "wowjs";
import moment from "moment-timezone";
// generales
import {Consumer} from "../context";
import Request from "../core/httpClient";
import DinamicModal from "../components/modal/DinamicModal";
import Controls from "../components/Controls";
import Sidebar from "../components/Sidebar";
import Paginator from "../components/Paginator";
// componentes
import TableHistorial from "../components/solicitud/tables/tableHistorial";
import ModalSolicitudHistorial from "../components/solicitud/modal/modalSolicitudHistorial";

const req = new Request();

class SolicitudesAbiertas extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			solicitudes: [],
			loadingSolicitudes: false,
			loadingVisitas: false,
			savingVisitas: false,
			modalName: "modalSolicitud",
			desde: new Date().setMonth(new Date().getMonth() - 1),
			hasta: new Date(),
			estado: 5,
			savingModal: false,
			newMode: true,
			areas: [],
			equipos: [],
			problemas: [],
			estados: [],
			estadosFiltro: [],
			proyectos: [],
			unidades: [],
			visitas: [],
			propietarios: [],
			visita: {},
			solicitud: {},
		};
	}

	async componentDidMount() {
		new WOW.WOW({live: false}).init();
		await this.setDate();
		this.loadSolicitudes();
		this.loadAreas();
		this.loadProblemas();
		this.loadEquipos();
		this.loadEstados();
		this.loadProyectos();
		this.loadUnidades();
		this.loadPropietarios();
	}

	async setDate() {
		let desde = new Date();
		desde.setMonth(desde.getMonth() - 1);

		await this.setState({desde: desde});
	}
	// load

	async loadSolicitudes() {
		this.setState({loadingSolicitudes: true});
		const data = {Desde: moment(this.state.desde).format("YYYY-MM-DD"), Hasta: moment(this.state.hasta).format("YYYY-MM-DD")};
		const requestApi = await req.get("solicitud/get/history", data);
		if (!requestApi.empty && !requestApi.error) {
			this.setState({solicitudes: requestApi.data});
		} else {
			this.setState({solicitudes: []});
		}
		this.setState({loadingSolicitudes: false});
	}

	async loadAreas() {
		this.setState({loadingAreas: true});
		let response = await req.get("config/get/areas", {});

		if (!response.error || !response.empty) {
			this.setState({areas: response.areas});
		} else {
			this.setState({areas: []});
		}
		this.setState({loadingAreas: false});
	}

	async loadEquipos() {
		this.setState({loadingEquipos: true});
		let response = await req.get("config/get/equipos", {});

		if (!response.error || !response.empty) {
			this.setState({equipos: response.equipos});
		} else {
			this.setState({equipos: []});
		}
		this.setState({loadingEquipos: false});
	}

	async loadProblemas() {
		this.setState({loadingProblemas: true});
		let response = await req.get("config/get/problemas", {});
		if (!response.error || !response.empty) {
			this.setState({problemas: response.problemas});
		} else {
			this.setState({problemas: []});
		}
		this.setState({loadingProblemas: false});
	}

	async loadEstados() {
		this.setState({loadingEstados: true});
		let response = await req.get("config/get/estados", {});
		if (!response.error || !response.empty) {
			this.setState({estados: response.estados.filter((edo) => edo.IdEstado !== 2)});
			this.setState({estadosFiltro: response.estados.filter((edo) => edo.IdEstado !== 2 && edo.IdEstado !== 1 && edo.IdEstado !== 6)});
		} else {
			this.setState({estados: []});
		}
		this.setState({loadingEstados: false});
	}

	async loadProyectos() {
		this.setState({loadingProyectos: true});
		const response = await req.get("proyectos/get", {});
		if (!response.empty && !response.error) {
			this.setState({proyectos: response.data});
		} else {
			this.setState({proyectos: []});
		}
		this.setState({loadingProyectos: false});
	}

	async loadUnidades() {
		this.setState({loadingUnidades: true});
		const response = await req.get("proyectos/get/all/unidades", {});
		if (!response.empty && !response.error) {
			this.setState({unidades: response.data});
		} else {
			this.setState({unidades: []});
		}
		this.setState({loadingUnidades: false});
	}

	async loadVisitas(IdSolicitud) {
		this.setState({loadingVisitas: true});
		const response = await req.get("solicitud/get/visitas", {IdSolicitud: IdSolicitud});
		if (!response.error) {
			this.setState({visitas: response.data});
		} else {
			this.setState({visitas: []});
		}
		this.setState({loadingVisitas: false});
	}

	async loadPropietarios() {
		this.setState({loadingPropietarios: true});
		const apiRequest = await req.get("propietarios/get/all", {});

		if (!apiRequest.error) {
			this.setState({propietarios: apiRequest.data ? apiRequest.data : []});
		} else {
			this.setState({propietarios: []});
		}
		this.setState({loadingPropietarios: false});
	}

	// generales

	async selectPage(page) {
		this.setState({page: page});
	}

	async openModal() {
		let modal = document.getElementById(this.state.modalName);

		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	async closeModal() {
		let modal = document.getElementById(this.state.modalName);
		if (modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		}
	}

	async updateJson(json) {
		this.setState(json);
	}

	async updateJsonDate(json) {
		await this.setState(json);
		this.loadSolicitudes();
	}

	// solicitudes
	async setSolicitud(solicitud) {
		this.setState({solicitud: solicitud, newMode: false, visita: {}});
		this.loadVisitas(solicitud.IdSolicitud);
		this.openModal();
		let tab1 = document.getElementById("tab-one");
		if (tab1) {
			tab1.checked = true;
		}
	}

	async updateSolicitud(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let solicitud = this.state.solicitud;
		let data = {
			IdSolicitud: solicitud.IdSolicitud,
			Fecha1: solicitud.Fecha1,
			Fecha2: solicitud.Fecha2,
			Fecha3: solicitud.Fecha3,
			ComentariosAplica: solicitud.ComentariosAplica,
			AplicaGarantia: solicitud.AplicaGarantia ? solicitud.AplicaGarantia : false,
			IdEstado: solicitud.IdEstado,
		};
		const requestApi = await req.post("solicitud/update", data);
		if (requestApi.response) {
			cogoToast.success("Solicitud actualizada con éxito.", {position: "top-right"});
			this.loadSolicitudes();
			this.closeModal();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}

		this.setState({savingModal: false});
	}

	async saveVisitas(event) {
		event.preventDefault();
		this.setState({savingVisitas: true});
		let solicitud = this.state.solicitud;
		let visita = this.state.visita;
		if (Object.keys(visita).length < 3) {
			cogoToast.error("Debe informar todos los campos", {position: "top-right"});
			this.setState({savingVisitas: false});
			return;
		}
		let data = {
			IdSolicitud: solicitud.IdSolicitud,
			Comentario: visita.Comentario,
			Responsable: visita.Responsable,
			Fecha: visita.Fecha,
		};
		const requestApi = await req.post("solicitud/create/visita", data);
		if (requestApi.response) {
			this.setState({visita: {}});
			this.loadVisitas(solicitud.IdSolicitud);
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingVisitas: false});
	}

	render() {
		return (
			<div className="column justify-center align-center proyectos">
				<Helmet>
					<title>ELEVA Capital Group | Historial</title>
				</Helmet>

				<div className="row full">
					<Sidebar />
					<div className="column align-center full">
						<div className="row">
							<div className="white-space-48"></div>
						</div>
						<div className="row full justify-center wow fadeIn">
							<div className="container justify-start align-center">
								<h3 className="color-secondary">Reportes</h3>
								<h3 className="color-secondary"> &nbsp;/&nbsp;</h3>
								<h4 className="color-darkgray">Historial</h4>
							</div>
						</div>
						<div className="row">
							<div className="white-space-8"></div>
						</div>
						<div className="row full justify-center">
							<div className="column full">
								<div className="row">
									<div className="white-space-8"></div>
								</div>
								<div className="row justify-center align-center">
									<Controls newHandler={this.state.newModal} showFilter={false}>
										<div className="row justify-end align-center full">
											<div className="column text-normal">
												<p className="color-secondary">
													<b>Desde:</b>
												</p>
											</div>
											<div className="column ">
												<input
													className="input"
													value={moment(this.state.desde).format("YYYY-MM-DD")}
													type="date"
													onChange={(event) => {
														this.updateJsonDate({desde: event.target.value});
													}}
												/>
											</div>
											<div className="column text-normal padding-left-semi">
												<p className="color-secondary">
													<b>Hasta:</b>
												</p>
											</div>
											<div className="column ">
												<input
													className="input"
													type="date"
													value={moment(this.state.hasta).format("YYYY-MM-DD")}
													onChange={(event) => {
														this.updateJsonDate({hasta: event.target.value});
													}}
												/>
											</div>
											<div className="column text-normal padding-left-semi">
												<p className="color-secondary">
													<b>Estado:</b>
												</p>
											</div>
											<div className="column">
												<select
													name=""
													className="input "
													value={this.state.estado}
													onChange={(event) => {
														this.setState({estado: event.target.value});
													}}
												>
													<option value="">Todos</option>
													{this.state.estadosFiltro.map((edo, key) => (
														<option key={key} value={edo.IdEstado}>
															{edo.Estado}
														</option>
													))}
												</select>
											</div>
										</div>
									</Controls>
								</div>
								<div className="row">
									<div className="white-space-16"></div>
								</div>
								<div className="row full justify-center wow fadeIn">
									<div className="container">
										<div className="column card-table full align-center">
											<TableHistorial
												loadingSolicitudes={this.state.loadingSolicitudes}
												solicitudes={this.state.solicitudes.filter((sol) => (this.state.estado !== "" ? sol.IdEstado == this.state.estado : true))}
												page={this.state.page}
												setSolicitud={this.setSolicitud.bind(this)}
											/>
											<div className="row">
												<div className="white-space-16"></div>
											</div>
											<div className="row justify-center align-center">
												<Paginator
													pages={Math.ceil(this.state.solicitudes.filter((sol) => (this.state.estado !== "" ? sol.IdEstado == this.state.estado : true)).length / 15)}
													setPage={this.selectPage.bind(this)}
												/>
											</div>
											<div className="row">
												<div className="white-space-16"></div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="white-space-16"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<DinamicModal
					idModal={"modalSolicitud"}
					sizeModalContainer={"normal"}
					title={"Solicitud"}
					btnTextSuccess={"Guardar"}
					success={this.updateSolicitud.bind(this)}
					showBtnSuccess={false}
					btnTextCancel={"Cerrar"}
					showBtnCancel={true}
					loadingSave={this.state.savingModal}
				>
					<ModalSolicitudHistorial
						updateJson={this.updateJson.bind(this)}
						solicitud={this.state.solicitud}
						areas={this.state.areas}
						equipos={this.state.equipos}
						problemas={this.state.problemas}
						estados={this.state.estados}
						proyectos={this.state.proyectos}
						unidades={this.state.unidades}
						newMode={this.state.newMode}
						saveVisitas={this.saveVisitas.bind(this)}
						visita={this.state.visita}
						visitas={this.state.visitas}
						propietarios={this.state.propietarios}
						loadingVisitas={this.state.loadingVisitas}
						savingVisitas={this.state.savingVisitas}
					/>
				</DinamicModal>
			</div>
		);
	}
}
export default withRouter(Consumer(SolicitudesAbiertas));

// node_module
import React from "react";
import Helmet from "react-helmet";
import {withRouter} from "react-router-dom";
import cogoToast from "cogo-toast";
import WOW from "wowjs";
// generales
import {Consumer} from "../context";
import Request from "../core/httpClient";
import DinamicModal from "../components/modal/DinamicModal";
import Controls from "../components/Controls";
import Sidebar from "../components/Sidebar";
import Paginator from "../components/Paginator";
// componentes
import TableFolder from "../components/documentos/tables/tableFolder";
import TableDocumentos from "../components/documentos/tables/tableDocumentos";
import ModalDocumento from "../components/documentos/modal/modalDocumento";
import ModalFolder from "../components/documentos/modal/modalFolder";
import DeleteModal from "../components/modal/DeleteModal";

const req = new Request();

class SolicitudesAbiertas extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1,
			documentos: [],
			folders: [],
			loadingDocumentos: false,
			loadingFolders: false,
			modalName: "",
			NombreFolder: "",
			openNew: this.openNewFolder.bind(this),
			saveDocumentoModal: this.createDocumento.bind(this),
			saveFolderModal: this.createDocumento.bind(this),
			savingModal: false,
			documento: {},
			folder: {},
			messageDelete: "",
			deleteHandler: this.deleteFolder.bind(this),
		};
	}

	async componentDidMount() {
		new WOW.WOW({live: false}).init();
		this.loadFolders();
	}

	// load

	async loadFolders() {
		this.setState({loadingFolders: true});

		const requestApi = await req.get("documentos/get/folders", {});
		if (!requestApi.empty && !requestApi.error) {
			this.setState({folders: requestApi.data});
		} else {
			this.setState({folders: []});
		}
		this.setState({loadingFolders: false});
	}

	async loadDocumentos() {
		this.setState({loadingDocumentos: true});
		let folder = this.state.folder;
		let data = {
			IdFolder: folder.IdFolder,
		};
		const requestApi = await req.get("documentos/get", data);
		if (!requestApi.empty && !requestApi.error) {
			this.setState({documentos: requestApi.data});
		} else {
			this.setState({documentos: []});
		}
		this.setState({loadingDocumentos: false});
	}

	// generales

	async selectPage(page) {
		this.setState({page: page});
	}

	async openModal() {
		let modal = document.getElementById(this.state.modalName);

		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	async closeModal() {
		let modal = document.getElementById(this.state.modalName);
		if (modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		}
	}

	async updateJson(json) {
		this.setState(json);
	}

	// // folders
	async openNewFolder() {
		await this.setState({folder: {}, saveFolderModal: this.createFolder.bind(this), modalName: "modalFolder"});
		this.openModal();
	}

	async showFolders() {
		await this.setState({
			folder: {},
			saveDocumentoModal: this.createFolder.bind(this),
			openNew: this.openNewFolder.bind(this),
			modalName: "modalFolder",
			showDocuments: false,
			NombreFolder: "",
		});
		this.loadFolders();
	}

	async setFolder(fld) {
		await this.setState({folder: {IdFolder: fld.IdFolder, NombreFolder: fld.NombreFolder}, saveFolderModal: this.updateFolder.bind(this), modalName: "modalFolder"});
		this.openModal();
	}

	async setDeleteFolder(fld) {
		await this.setState({folder: fld, modalName: "deleteModal", messageDelete: "¿Está seguro de eliminar la carpeta ?", deleteHandler: this.deleteFolder.bind(this)});
		this.openModal();
	}

	async createFolder(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let folder = this.state.folder;
		let data = {
			NombreFolder: folder.NombreFolder,
		};
		const requestApi = await req.post("documentos/create/folder", data);
		if (requestApi.response) {
			cogoToast.success("Solicitud actualizada con éxito.", {position: "top-right"});
			this.loadFolders();
			this.closeModal();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingModal: false});
	}

	async updateFolder(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let folder = this.state.folder;
		let data = {
			IdFolder: folder.IdFolder,
			NombreFolder: folder.NombreFolder,
			Status: 1,
		};
		const requestApi = await req.post("documentos/update/folder", data);
		if (requestApi.response) {
			cogoToast.success("Solicitud actualizada con éxito.", {position: "top-right"});
			this.loadFolders();
			this.closeModal();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingModal: false});
	}

	async deleteFolder(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let folder = this.state.folder;
		let data = {
			IdFolder: folder.IdFolder,
			NombreFolder: folder.NombreFolder,
			Status: 0,
		};
		const requestApi = await req.post("documentos/update/folder", data);
		if (requestApi.response) {
			cogoToast.success("Solicitud actualizada con éxito.", {position: "top-right"});
			this.loadFolders();
			this.closeModal();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingModal: false});
	}

	// // documents

	async openNewDocumentModal() {
		let folder = this.state.folder;
		await this.setState({documento: {}, saveDocumentoModal: this.createDocumento.bind(this), modalName: "modalDocumento"});
		this.openModal();
	}

	async showDocuments(fld) {
		await this.setState({
			folder: fld,
			modalName: "modalDocumento",
			openNew: this.openNewDocumentModal.bind(this),
			showDocuments: true,
			NombreFolder: fld.NombreFolder,
		});
		this.loadDocumentos();
	}

	async viewDocument(doc) {
		var a = document.createElement("A");
		var filePath = doc.path;
		a.href = filePath;
		a.download = filePath.substr(filePath.lastIndexOf("/") + 1);
		a.setAttribute("target", "_blank");
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	async setDeleteDocument(doc) {
		await this.setState({documento: doc, modalName: "deleteModal", messageDelete: "¿Está seguro de eliminar el documento?", deleteHandler: this.deleteDocumento.bind(this)});
		this.openModal();
	}

	async createDocumento(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let documento = this.state.documento;
		let folder = this.state.folder;
		let file1 = documento.Archivo;
		let data = {
			NombreDocumento: documento.NombreDocumento,
			IdFolder: folder.IdFolder,
		};
		const requestApi = await req.postFile("documentos/create", [file1], data);
		if (requestApi.response) {
			cogoToast.success("Solicitud actualizada con éxito.", {position: "top-right"});
			this.loadDocumentos();
			this.closeModal();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}

		this.setState({savingModal: false});
	}

	async deleteDocumento(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let documento = this.state.documento;
		let data = {
			IdDocumento: documento.IdDocumento,
			NombreDocumento: documento.NombreDocumento,
			IdFolder: documento.IdFolder,
			Status: 0,
		};
		const requestApi = await req.post("documentos/update", data);
		if (requestApi.response) {
			cogoToast.success("Solicitud actualizada con éxito.", {position: "top-right"});
			this.loadDocumentos();
			this.closeModal();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}

		this.setState({savingModal: false});
	}

	render() {
		return (
			<div className="column justify-center align-center proyectos">
				<Helmet>
					<title>ELEVA Capital Group | Documentos</title>
				</Helmet>

				<div className="row full">
					<Sidebar />
					<div className="column align-center full">
						<div className="row">
							<div className="white-space-48"></div>
						</div>
						<div className="row full justify-center wow fadeIn">
							<div className="container justify-start align-center">
								<h3 className="color-secondary">Documentos</h3>
								<h3 className="color-secondary">&nbsp;/&nbsp;</h3>
								<h4 className="color-darkgray">{this.state.NombreFolder !== "" ? this.state.NombreFolder : "Carpetas"} </h4>
							</div>
						</div>
						<div className="row">
							<div className="white-space-8"></div>
						</div>
						<div className="row full justify-center">
							<div className="column full">
								<div className="row">
									<div className="white-space-8"></div>
								</div>
								<div className="row justify-center align-center">
									<Controls
										newHandler={this.state.openNew}
										showBack={this.state.showDocuments}
										backHandler={() => {
											this.showFolders();
										}}
										backLabel={"Carpetas"}
										showFilter={true}
									/>
								</div>
								<div className="row">
									<div className="white-space-16"></div>
								</div>
								<div className="row full justify-center wow fadeIn">
									<div className="container">
										<div className="column card-table full align-center">
											{this.state.showDocuments ? (
												<TableDocumentos
													page={this.state.page}
													documentos={this.state.documentos}
													loadingDocumentos={this.state.loadingDocumentos}
													setDeleteDocument={this.setDeleteDocument.bind(this)}
													viewDocument={this.viewDocument.bind(this)}
												/>
											) : (
												<TableFolder
													loadingFolders={this.state.loadingFolders}
													folders={this.state.folders}
													page={this.state.page}
													setFolder={this.setFolder.bind(this)}
													setDeleteFolder={this.setDeleteFolder.bind(this)}
													showDocuments={this.showDocuments.bind(this)}
												/>
											)}

											<div className="row">
												<div className="white-space-16"></div>
											</div>
											<div className="row justify-center align-center">
												<Paginator
													pages={Math.ceil((this.state.showDocuments ? this.state.documentos.length : this.state.folders.length) / 15)}
													setPage={this.selectPage.bind(this)}
												/>
											</div>
											<div className="row">
												<div className="white-space-16"></div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="white-space-16"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<DinamicModal
					idModal={"modalFolder"}
					sizeModalContainer={"mini"}
					title={"Carpeta"}
					btnTextSuccess={"Guardar"}
					success={this.state.saveFolderModal}
					showBtnSuccess={true}
					btnTextCancel={"Cerrar"}
					showBtnCancel={true}
					loadingSave={this.state.savingModal}
				>
					<ModalFolder updateJson={this.updateJson.bind(this)} folder={this.state.folder} />
				</DinamicModal>
				<DinamicModal
					idModal={"modalDocumento"}
					sizeModalContainer={"mini"}
					title={"Documento"}
					btnTextSuccess={"Guardar"}
					success={this.state.saveDocumentoModal}
					showBtnSuccess={true}
					btnTextCancel={"Cerrar"}
					showBtnCancel={true}
					loadingSave={this.state.savingModal}
				>
					<ModalDocumento updateJson={this.updateJson.bind(this)} documento={this.state.documento} folders={this.state.folders} />
				</DinamicModal>

				<DeleteModal id="deleteModal" title="Eliminar" message={this.state.messageDelete} acceptMethod={this.state.deleteHandler} savingModal={this.state.savingModal} />
			</div>
		);
	}
}
export default withRouter(Consumer(SolicitudesAbiertas));

import React, { useEffect, useState } from 'react';
import UploadIcon from './UploadIcon.svg';
import FlipMove from 'react-flip-move';
import CogoToast from 'cogo-toast';
import './style.scss';

const ERROR = {
	NOT_SUPPORTED_EXTENSION: 'NOT_SUPPORTED_EXTENSION',
	FILESIZE_TOO_LARGE: 'FILESIZE_TOO_LARGE',
	fileSizeError: " file size is too big",
  	fileTypeError: " is not a supported file extension"
}

const ImagesUpload = (props) => {
	const [errorArray, setErrorArray] = useState([]);
	const [maxFileSize, setMaxFileSize] = useState(2097152);
	const [imgExtension, setImgExtension] = useState(['.jpg', '.gif', '.png', 'jpeg']);
	const [pictures, setPictures] = useState([]);
	const [files, setFiles] = useState([]);

	let inputFile;

	useEffect(() => {
		if (Array.isArray(props.defaultPictures)) {

			if (props.defaultPictures.length > 0) {
				for (let key in props.defaultPictures) {
					const element = props.defaultPictures[key];
					console.log(element);
					createFile(element);
				}
				setPictures(props.defaultPictures);
			} else {
				setPictures([]);
				setFiles([]);
				//console.log('limpio',pictures);
			}
		}
	}, [props.defaultPictures]);

	async function createFile(url) {
		let newPictures = pictures || [];
		let newFiles = files || [];
		let response = await fetch(url);
		let data = await response.blob();
		let metadata = {
			type: 'image/jpeg'
		};
		let file = new File([data], `${newPictures.length}.jpg`, metadata);

		newPictures.push(url);
		newFiles.push(file);

		setFiles(newFiles);
	  }

	function triggerFileUpload() {
		inputFile.click();
	}

	function hasExtension(fileName) {
		const pattern = '(' + imgExtension.join('|').replace(/\./g, '\\.') + ')$';
		return new RegExp(pattern, 'i').test(fileName);
	}

	function onUploadClick(e) {
		e.target.value = null;
	}

	function  onDropFile(e) {
		const dropFiles = e.target.files;
		const allFilePromises = [];
		const fileErrors = [];

		// Iterate over all uploaded files
		if ( files.length >= 3 ) {
			CogoToast.warn('Solo se permite subir hasta 3 imagenes')
			return;
		}

		for (let i = 0; i < dropFiles.length; i++) {
			let file = dropFiles[i];
			let fileError = {
				name: file.name,
			};
			// Check for file extension
			if (!hasExtension(file.name)) {
				fileError = Object.assign(fileError, {
					type: ERROR.NOT_SUPPORTED_EXTENSION
				});

				fileErrors.push(fileError);

				continue;
			}
			// Check for file size
			if(file.size > maxFileSize) {
				fileError = Object.assign(fileError, {
					type: ERROR.FILESIZE_TOO_LARGE
				});

				fileErrors.push(fileError);

				continue;
			}
		
			allFilePromises.push(readFile(file));
		}
	
		setErrorArray(fileErrors);
	
		const { singleImage } = props;
	
		Promise.all(allFilePromises).then(newFilesData => {
			const dataURLs = singleImage?[]:pictures.slice();
			const dataFiles = singleImage?[]:files.slice();

			newFilesData.forEach(newFileData => {
				dataURLs.push(newFileData.dataURL);
				dataFiles.push(newFileData.file);
			});
	
			setPictures(dataURLs);
			setFiles(dataFiles);

			if (props.onChange) {
				props.onChange(dataFiles, dataURLs);
			}
		});
	}

	function readFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
	
			// Read the image via FileReader API and save image result in state.
			reader.onload = function (e) {
				// Add the file name to the data URL
				let dataURL = e.target.result;
				dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
				resolve({file, dataURL});
			};
		
			reader.readAsDataURL(file);
		});
	}

	function removeImage(picture) {
		const removeIndex = pictures.findIndex(e => e === picture);
		const filteredPictures = pictures.filter((e, index) => index !== removeIndex);
		const filteredFiles = files.filter((e, index) => index !== removeIndex);
	
		setPictures(filteredPictures);
		setFiles(filteredFiles);

		if (props.onChange) {
			props.onChange(filteredFiles, filteredPictures);
		}
	}

	function renderErrors() {
		return errorArray.map((fileError, index) => {
			return (
				<div className={'errorMessage'} key={index}>
					* {fileError.name} {fileError.type === ERROR.FILESIZE_TOO_LARGE ? ERROR.fileSizeError: ERROR.fileTypeError}
				</div>
			);
		});
	}

	return (
		<div className="images-upload-files">
			<div className="upload-container">
				<img src={UploadIcon} className="icon" alt="Upload Icon" />
				<p className="label">
					Tamaño maximo por imagen: 2 MB.
				</p>
				<div className="errorsContainer">
					{renderErrors()}
				</div>
				<button
					type={props.buttonType || 'button'}
					className={'chose-file-button'}
					onClick={triggerFileUpload}
				>
					{props.buttonText || 'Subir imágenes'}
				</button>
				<input
					type="file"
					ref={input => inputFile = input}
					name={props.name}
					multiple={!props.singleImage}
					onChange={onDropFile}
					onClick={onUploadClick}
					accept={"image/*"}
				/>
				<div className="pictures-row">
					<FlipMove
						enterAnimation	=	"fade"
						leaveAnimation	=	"fade"
						style			=	{{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							width: "100%",
							overflow: 'auto'
						}}>
						{pictures.map((picture, index) => {
							return (
								<div key={index} className="uploadPictureContainer">
									<div className="deleteImage" onClick={() => removeImage(picture)}>
										&times;
									</div>
									<img src={picture} className="uploadPicture" alt="preview"/>
								</div>
							);
						})}
					</FlipMove>
				</div>
			</div>
		</div>
	)
}

export default ImagesUpload;
import React, {useState, useEffect} from "react";
import moment from "moment-timezone";

const ModalPropietario = (props) => {
	const [usuario, setUsuario] = useState({});
	const [perfiles, setPerfiles] = useState([]);
	const [unidades, setUnidades] = useState([]);
	const [proyectos, setProyectos] = useState([]);

	useEffect(() => {
		setPerfiles(props.perfiles);
	}, [props.perfiles]);

	useEffect(() => {
		setUsuario(props.usuario);
	}, [props.usuario]);

	useEffect(() => {
		setUnidades(props.unidades);
	}, [props.unidades]);

	useEffect(() => {
		setProyectos(props.proyectos);
	}, [props.proyectos]);

	function updateUsuario(usr) {
		props.updateState({usuario: usr});
	}

	return (
		<div className="full row align-center">
			<div className="column full">
			<div className="white-space-16" />
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Usuario (correo):</b>
						</p>
					</div>
					<div className="column full">
						<input
							type="text"
							className="input input-modals"
							value={usuario.Email ? usuario.Email : ""}
							onChange={(event) => {
								let usr = usuario;
								usr.Email = event.target.value;
								updateUsuario(usr);
							}}
							autoComplete="off"
							minLength={2}
							required
						/>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Nombre:</b>
						</p>
					</div>
					<div className="column full">
						<input
							type="text"
							className="input input-modals"
							value={usuario.Nombre ? usuario.Nombre : ""}
							onChange={(event) => {
								let usr = usuario;
								usr.Nombre = event.target.value;
								updateUsuario(usr);
							}}
							autoComplete="off"
							minLength={2}
							required
						/>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Apellidos:</b>
						</p>
					</div>
					<div className="column full">
						<input
							type="text"
							className="input input-modals"
							value={usuario.Apellidos ? usuario.Apellidos : ""}
							onChange={(event) => {
								let usr = usuario;
								usr.Apellidos = event.target.value;
								updateUsuario(usr);
							}}
							autoComplete="off"
							minLength={2}
							required
						/>
					</div>
				</div>
				<div className="white-space-8"></div>
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Teléfono:</b>
						</p>
					</div>
					<div className="column full">
						<input
							type="text"
							className="input input-modals"
							value={usuario.Telefono ? usuario.Telefono : ""}
							onChange={(event) => {
								let usr = usuario;
								usr.Telefono = event.target.value;
								updateUsuario(usr);
							}}
							autoComplete="off"
							minLength={2}
							required
						/>
					</div>
				</div>
				
				<div className="white-space-8"></div>
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Proyecto:</b>
						</p>
					</div>
					<div className="column column-small">
						<select
							name=""
							className="input input-modals"
							value={usuario.IdProyecto ? usuario.IdProyecto : ""}
							required
							onChange={(event) => {
								let usr = usuario;
								usr.IdProyecto = event.target.value;
								usr.IdUnidad = "";
								updateUsuario(usr);
							}}
						>
							<option value="" disabled>
								Sin Proyecto
							</option>
							{proyectos.map((pry, key) => (
								<option key={key} value={pry.IdProyecto}>
									{pry.Nombre}
								</option>
							))}
						</select>
					</div>
					<div className="column label-middle">
						<p>
							<b>Unidad:</b>
						</p>
					</div>
					<div className="column column-small">
						<select
							name=""
							className="input input-modals"
							value={usuario.IdUnidad ? usuario.IdUnidad : ""}
							required
							onChange={(event) => {
								let usr = usuario;
								usr.IdUnidad = event.target.value;
								updateUsuario(usr);
							}}
						>
							<option value="" disabled>
								Elige Unidad
							</option>
							{unidades
								.filter((un) => (usuario.IdProyecto ? usuario.IdProyecto == un.IdProyecto : true))
								.map((unidad, key) => (
									<option key={key} value={unidad.IdUnidad}>
										{unidad.Numero}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className="white-space-8"></div>
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>No. Cliente:</b>
						</p>
					</div>
					<div className="column column-small">
					<input
							type="text"
							className="input input-modals"
							value={usuario.NumeroContrato ? usuario.NumeroContrato : ""}
							onChange={(event) => {
								let usr = usuario;
								usr.NumeroContrato = event.target.value;
								updateUsuario(usr);
							}}
							autoComplete="off"
							minLength={2}
							required
						/>
					</div>
					<div className="column label-middle">
						<p>
							<b>Meses Garantía:</b>
						</p>
					</div>
					<div className="column column-small">
						<input
							type="number"
							className="input input-modals"
							value={usuario.MesesGarantia ? usuario.MesesGarantia : ""}
							onChange={(event) => {
								let usr = usuario;
								usr.MesesGarantia = event.target.value;
								updateUsuario(usr);
							}}
							autoComplete="off"
							required
						/>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Fecha Compra:</b>
						</p>
					</div>
					<div className="column column-small">
						<input
							className="input input-modals"
							type="date"
							value={usuario.FechaCompra ? moment(usuario.FechaCompra).format("YYYY-MM-DD") : ""}
							onChange={(event) => {
								let usr = usuario;
								usr.FechaCompra = event.target.value;
								updateUsuario(usr);
							}}
							autoComplete="off"
							required
						/>
					</div>
					<div className="column label-middle">
						<p>
							<b>Fecha Entrega:</b>
						</p>
					</div>
					<div className="column column-small">
						<input
							className="input"
							type="date"
							value={usuario.FechaEntrega ? moment(usuario.FechaEntrega).format("YYYY-MM-DD") : ""}
							onChange={(event) => {
								let usr = usuario;
								usr.FechaEntrega = event.target.value;
								updateUsuario(usr);
							}}
							autoComplete="off"
						/>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Comentarios:</b>
						</p>
					</div>
					<div className="column full">
						<textarea
							className="input input-modals"
							type="date"
							value={usuario.Comentarios ? usuario.Comentarios : ""}
							onChange={(event) => {
								let usr = usuario;
								usr.Comentarios = event.target.value;
								updateUsuario(usr);
							}}
							autoComplete="off"
							required
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ModalPropietario;

import React, {Component} from "react";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

// Conexión con el Context API
import {GlobalContext} from "../context";

// Páginas Web
import Login from "../pages/Login";
import RecoverPassword from "../components/recover";
import Error from "../pages/Error";
import Dashboard from "../pages/Dashboard";
import Proyectos from "../pages/Proyectos";
import Catalogos from "../pages/Catalogos";
import Propietarios from "../pages/Propietarios";
import Usuarios from "../pages/Usuarios";
import Abiertas from "../pages/garantiasAbiertas";
import Historial from "../pages/garantiasHistorial";
import Eventos from "../pages/Eventos";
import Consultas from "../pages/Consultas";
import Notificaciones from "../pages/Notificaciones";
import Documentos from "../pages/Documentos";

const PrivateRoute = ({component: Component, ...rest}) => (
	<Route
		{...rest}
		render={(props) => {
			let usuario = window.localStorage.getItem("bdusr");
			if (usuario) {
				usuario = JSON.parse(usuario)
				if (usuario.auth) {
					return <Component {...props} />;
				} else {
					return <Redirect to={{pathname: "/login", state: {from: props.location}}} />;
				}
			} else {
				return <Redirect to={{pathname: "/login", state: {from: props.location}}} />;
			}
		}}
	/>
);

class Routes extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {}

	renderRoutes() {
		// RUTAS PRIVADAS
		let routes = [
			{path: "/", component: Dashboard, private: true},
			{path: "/configuracion/proyectos", component: Proyectos, private: true},
			{path: "/configuracion/catalogos", component: Catalogos, private: true},
			{path: "/configuracion/propietarios", component: Propietarios, private: true},
			{path: "/configuracion/usuarios", component: Usuarios, private: true},
			{path: "/solicitudes/abiertos", component: Abiertas, private: true},
			{path: "/solicitudes/historial", component: Historial, private: true},
			{path: "/eventos", component: Eventos, private: true},
			{path: "/notificaciones", component: Notificaciones, private: true},
			{path: "/reportes", component: Consultas, private: true},
			{path: "/documentos", component: Documentos, private: true},
		];

		let routesRender = [];

		routesRender = routes.map((route, index) => {
			return <PrivateRoute key={index} exact path={route.path} component={route.component} />;
		});
		routesRender.push(<Route exact path={"/login"} component={Login} key={routes.length + 1} />);
		routesRender.push(<Route exact path={"/recover"} component={RecoverPassword} key={routes.length + 2} />);
		routesRender.push(<Route exact path={"/recover/:id"} component={RecoverPassword} key={routes.length + 2} />);
		routesRender.push(<PrivateRoute component={Error} key={routes.length + 2} />);

		return routesRender;
	}

	render() {
		return (
			<GlobalContext>
				<BrowserRouter>
					<div className="flex main">
						<div className="column full">
							<Switch>{this.renderRoutes()}</Switch>
						</div>
					</div>
				</BrowserRouter>
			</GlobalContext>
		);
	}
}

export default Routes;

import React, {useEffect, useState} from "react";

const TableUsuarios = (props) => {
	const [usuarios, setUsuarios] = useState([]);
	const [loadingUsuarios, setLoadingUsuarios] = useState(false);

	useEffect(() => {
		setUsuarios(props.usuarios);
		setLoadingUsuarios(props.loadingUsuarios);
	}, [props.usuarios, props.loadingUsuarios]);

	return (
		<div className="table-responsive full">
			<table>
				<thead>
					<tr>
						<th className="text-left">Nombre</th>
						<th className="text-left">Apellidos</th>
						<th className="text-center">Proyecto</th>
						<th className="text-center">Unidad</th>
						<th className="text-center">No. Cliente</th>
						<th className="text-center th-md"></th>
						<th className="text-center th-md"></th>
					</tr>
				</thead>
				<tbody>
					{loadingUsuarios ? (
						<tr>
							<td className="text-center" colSpan="7">
								<i className="fas fa-spinner fa-spin"></i>
							</td>
						</tr>
					) : usuarios ? (
						usuarios.length == 0 ? (
							<tr>
								<td className="text-center" colSpan="7">
									Sin datos que mostrar
								</td>
							</tr>
						) : (
							usuarios.slice((props.page - 1) * 15, props.page * 15).map((usuario, key) => (
								<tr key={key}>
									<td>{usuario.Nombre}</td>
									<td>{usuario.Apellidos}</td>
									<td className="text-center">{usuario.NombreProyecto}</td>
									<td className="text-center">{usuario.Numero}</td>
									<td className="text-center">{usuario.NumeroContrato}</td>
									<td className="text-center">
										<button
											className=" btn-small btn-empty"
											type="button"
											onClick={() => {
												props.setUsuario(usuario);
											}}
										>
											<i className="font-small fas fa-edit" />
										</button>
									</td>
									<td className="text-center">
										<button
											className=" btn-small btn-empty"
											type="button"
											onClick={() => {
												props.setUsuarioDelete(usuario);
											}}
										>
											<i className="font-small fas fa-trash-alt" />
										</button>
									</td>
								</tr>
							))
						)
					) : null}
				</tbody>
			</table>
		</div>
	);
};
export default TableUsuarios;

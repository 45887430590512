import React from "react";

const ResetPassword = (props) => {
	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="row full align-center">
					<div className="column full color-black">
						<p className="color-black">
							<b>Contraseña anterior:</b>
						</p>
					</div>
					<div className="column full">
						<input
							className="input"
							type="password"
							required
							value={props.oldPassword}
							onChange={(event) => {
								props.setOldPassword(event.target.value);
							}}
						></input>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="row full align-center">
					<div className="column full color-black">
						<p className="color-black">
							<b>Nueva Contraseña:</b>
						</p>
					</div>
					<div className="column full">
						<input
							className="input"
							type="password"
							required
							value={props.newPassword}
							onChange={(event) => {
								props.setNewPassword(event.target.value);
							}}
						></input>
					</div>
				</div>
				<div className="white-space-8" />
			</div>
		</div>
	);
};
export default ResetPassword;

import React, {useState, useEffect} from "react";

const ModalNotificacion = (props) => {
	const [notificacion, setNotificacion] = useState({});
	const [proyectos, setProyectos] = useState([]);
	const [unidades, setUnidades] = useState([]);
	const [personas, setPersonas] = useState([]);

	useEffect(() => {
		setNotificacion(props.notificacion);
	}, [props.notificacion]);
	
	useEffect(() => {
		setProyectos(props.proyectos);
	}, [props.proyectos]);

	useEffect(() => {
		setUnidades(props.unidades);
	}, [props.unidades]);

	useEffect(() => {
		setPersonas(props.personas);
	}, [props.personas]);

	function updateNotificacion(ntc) {
		props.updateJson({notificacion: ntc});
	}

	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="white-space-16"></div>
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Proyecto:</b>
						</p>
					</div>
					<div className="column full">
						<select
							className="input"
							value={notificacion.IdProyecto ? notificacion.IdProyecto : ""}
							onChange={(event) => {
								let not = notificacion;
								not.IdProyecto = event.target.value;
								updateNotificacion(not);
							}}
						>
							<option value="">Todos</option>
							{proyectos.map((pry, key) => (
								<option key={key} value={pry.IdProyecto}>
									{pry.Nombre}
								</option>
							))}
						</select>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Unidad:</b>
						</p>
					</div>
					<div className="column full">
						<select
							className="input"
							value={notificacion.IdUnidad ? notificacion.IdUnidad : ""}
							onChange={(event) => {
								let not = notificacion;
								not.IdUnidad = event.target.value;
								updateNotificacion(not);
							}}
						>
							<option value="">Todos</option>
							{unidades
								.filter((un) => (notificacion.IdProyecto ? notificacion.IdProyecto == un.IdProyecto : false))
								.map((unidad, key) => (
									<option key={key} value={unidad.IdUnidad}>
										{unidad.Numero}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Persona:</b>
						</p>
					</div>
					<div className="column full">
						<select
							className="input"
							value={notificacion.IdPersona ? notificacion.IdPersona : ""}
							onChange={(event) => {
								let not = notificacion;
								not.IdPersona = event.target.value;
								updateNotificacion(not);
							}}
						>
							<option value="">Todos</option>
							{personas
								.filter((pr) => (notificacion.IdUnidad ? notificacion.IdUnidad == pr.IdUnidad : false))
								.map((persona, key) => (
									<option key={key} value={persona.IdPersona}>
										{persona.Nombre}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className="white-space-8" />
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Mensaje:</b>
						</p>
					</div>
					<div className="column full">
						<textarea
							className="input"
							value={notificacion.Mensaje ? notificacion.Mensaje : ""}
							onChange={(event) => {
								let not = notificacion;
								not.Mensaje = event.target.value;
								updateNotificacion(not);
							}}
						></textarea>
					</div>
				</div>
				<div className="white-space-8" />
			</div>
		</div>
	);
};
export default ModalNotificacion;

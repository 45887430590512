import React, {useState, useEffect} from "react";
import Draggable from "react-draggable";

const DinamicModal = (props) => {
	const [sizeModal, setSizeModal] = useState("column dinamic-modal-container dinamic-modal-normal");

	useEffect(() => {
		switch (props.sizeModalContainer) {
			case "big":
				setSizeModal("column dinamic-modal-container dinamic-modal-big");
				break;
			case "normal":
				setSizeModal("column dinamic-modal-container dinamic-modal-normal");
				break;
			case "small":
				setSizeModal("column dinamic-modal-container dinamic-modal-small");
				break;
			case "mini":
				setSizeModal("column dinamic-modal-container dinamic-modal-mini");
				break;
			case "micro":
				setSizeModal("column dinamic-modal-container dinamic-modal-micro");
				break;
			case "super":
				setSizeModal("column dinamic-modal-container dinamic-modal-super-big");
				break;
			default:
				setSizeModal("column dinamic-modal-container dinamic-modal-normal");
				break;
		}
	}, [props]);

	function closeModal(idModal) {
		if (props.closeModal) {
			props.closeModal();
		}
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("show-dinamic-modal");
			modal.classList.add("hide-dinamic-modal");
		}
	}

	return (
		<div className="overlay hide-dinamic-modal" id={props.idModal}>
			<Draggable handle=".dinamic-header">
				<div className={sizeModal}>
					<div className="dinamic-header row justify-center">
						<div className="column modal-container justify-center">
							<h4 className="title">{props.title}</h4>
						</div>
						<button className="btn-modal-close justify-center align-center" onClick={closeModal.bind(this, props.idModal)}>
							<i className="fas fa-times"></i>
						</button>
					</div>

					<div className="dinamic-body row justify-center">
						<div className="column modal-container">
							<form className="flex column justify-center" onSubmit={props.success}>
								{props.children}
								{props.message && (
									<div className="row full">
										<div className="column">
											<div className="white-space-32"></div>
											<p className="modal-message">
												<i className="fas fa-bookmark"></i> &nbsp;
												{props.message}
											</p>
										</div>
									</div>
								)}
								<div className="white-space-16"></div>
								<div className="dinamic-footer row justify-end">
									{props.loadingSave ? (
										<div className="row justify-center">
											<i className="fas fa-spinner fa-spin"></i>
										</div>
									) : (
										<div className="row justify-end full">
											{props.showBtnCancel && (
												<button type="button" className="btn btn-small btn-action-cancel" onClick={closeModal.bind(this, props.idModal)}>
													{/* <i className="fas fa-arrow-left"></i> */}
													<i className="fas fa-times"></i>
													<i className="weight-bold"> {props.btnTextCancel}</i>
												</button>
											)}
											{props.showBtnSuccess && (
												<button type="submit" className="btn btn-small btn-action-success">
													<i className="weight-bold"> {props.btnTextSuccess}</i>
												</button>
											)}
										</div>
									)}
								</div>
							</form>
						</div>
					</div>
					<div className="white-space-16"></div>
				</div>
			</Draggable>
		</div>
	);
};

export default DinamicModal;

import React, {useEffect, useState} from "react";

const TableUnidad = (props) => {
	const [unidades, setUnidades] = useState([]);

	useEffect(() => {
		setUnidades(props.unidades);
	}, [props.unidades]);

	return (
		<div className="table-responsive full wow fadeInRight" id="tableUnidades" data-wow-duration="0.1s">
			<table>
				<thead>
					<tr>
						<th className="text-left">Número</th>
						<th className="text-center">Tipo Unidad</th>
						<th className="text-center th-lg"></th>
					</tr>
				</thead>
				<tbody>
					{props.loadingUnidades ? (
						<tr>
							<td className="text-center" colSpan="5">
								<i className="fas fa-spinner fa-spin"></i>
							</td>
						</tr>
					) : unidades.length == 0 ? (
						<tr>
							<td className="text-center" colSpan="5">
								Sin datos que mostrar
							</td>
						</tr>
					) : (
						unidades.slice((props.page - 1) * 15, props.page * 15).map((unidad, key) => (
							<tr key={key}>
								<td className="text-left">{unidad.Numero}</td>
								<td className="text-center">{unidad.NombreUnidad}</td>
								<td className="text-center th-lg">
									<button
										className=" btn-small btn-empty"
										type="button"
										onClick={() => {
											props.setToUpdateUnidad(unidad);
										}}
									>
										<i className="font-small fas fa-edit" />
									</button>
								</td>
							</tr>
						))
					)}
				</tbody>
			</table>
		</div>
	);
};
export default TableUnidad;

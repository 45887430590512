import React from "react";

const ModalCatalogo = (props) => {
	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="white-space-16"></div>
				<div className="row full align-center">
					<div className="column label-medium">
						<p>
							<b>Nombre:</b>
						</p>
					</div>
					<div className="column full">
						<input
							type="text"
							className="input input-modals"
							value={props.value}
							onChange={(event) => {
								props.updateValue(event.target.value);
							}}
							autoComplete="off"
							minLength={2}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ModalCatalogo;

import React, {useState, useEffect} from "react";

const Controls = (props) => {
	const [showBack, setShowBack] = useState(false);
	const [showFilter, setShowFilter] = useState(true);
	const [backLabel, setBackLabel] = useState("");

	useEffect(() => {
		setShowBack(props.showBack);
	}, [props.showBack]);

	useEffect(() => {
		setBackLabel(props.backLabel);
	}, [props.backLabel]);

	useEffect(() => {
		setShowFilter(props.showFilter === undefined ? true : props.showFilter);
	}, [props.showFilter]);

	return (
		<div className="container">
			{showBack ? (
				<div className="justify-start align-center full">
					<button className="btn btn-empty btn-small color-white" onClick={props.backHandler}>
						<i className="fas fa-arrow-left"></i>
						&nbsp; {backLabel ? backLabel : "Atrás"}
					</button>
				</div>
			) : (
				""
			)}
			<div className="justify-end align-center full wow fadeIn">
				{props.children}
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				{showFilter ? (
					<button className="btn btn-secondary btn-small color-white " onClick={props.newHandler}>
						<i className="fas fa-plus"></i>
						&nbsp; Nuevo
					</button>
				) : null}
			</div>
		</div>
	);
};

export default Controls;

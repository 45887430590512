// node_module
import React from "react";
import Helmet from "react-helmet";
import {withRouter} from "react-router-dom";
import cogoToast from "cogo-toast";
import WOW from "wowjs";
// generales
import {Consumer} from "../context";
import Request from "../core/httpClient";
import DinamicModal from "../components/modal/DinamicModal";
import Controls from "../components/Controls";
import Sidebar from "../components/Sidebar";
import Paginator from "../components/Paginator";
// componentes
import TableProyectos from "../components/proyectos/tables/tableProyectos";
import TableTipoUnidad from "../components/proyectos/tables/tableTipoUnidad";
import TableUnidades from "../components/proyectos/tables/tableUnidades";
import ModalProyecto from "../components/proyectos/modal/modalProyecto";
import ModalTipoUnidad from "../components/proyectos/modal/modalTipoUnidad";
import ModalUnidad from "../components/proyectos/modal/modalUnidad";

const req = new Request();

class Proyectos extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 0,
			proyectos: [],
			tipos: [],
			unidades: [],
			proyecto: {},
			tipoUnidad: {},
			unidad: {},
			showProyectos: true,
			loadingProyectos: false,
			showTipos: false,
			loadingTipos: false,
			showUnidades: false,
			loadingUnidades: false,
			showBack: false,
			backHandler: () => {},
			backLabel: "",
			modalName: "newProyecto",
			newModal: this.openProyect.bind(this),
			saveProyecto: this.createProyecto.bind(this),
			saveUnidad: this.createUnidad.bind(this),
			saveTipoUnidad: this.createTipo.bind(this),
			areas: [],
			equipos: [],
			title: "",
		};
	}

	componentDidMount() {
		this.loadProyectos();
		new WOW.WOW({live: false}).init();
	}

	// generales

	async selectPage(page) {
		this.setState({page: page});
	}

	async openModal() {
		let modal = document.getElementById(this.state.modalName);

		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	async closeModal() {
		let modal = document.getElementById(this.state.modalName);
		if (modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		}
	}

	async updateJson(json) {
		this.setState(json);
	}

	// load

	async loadProyectos() {
		this.setState({loadingProyectos: true});
		const response = await req.get("proyectos/get", {});
		if (!response.empty && !response.error) {
			this.setState({proyectos: response.data});
		} else {
			this.setState({proyectos: []});
		}
		this.setState({loadingProyectos: false});
	}

	async loadTiposUnidades() {
		this.setState({loadingTipos: true});
		let proyecto = this.state.proyecto;
		let data = {
			IdProyecto: proyecto.IdProyecto,
		};
		const response = await req.get("proyectos/get/tipos/unidades", data);
		if (!response.empty && !response.error) {
			await this.setState({tipos: response.data});
		} else {
			await this.setState({tipos: []});
		}
		this.setState({loadingTipos: false});
	}

	async loadUnidades() {
		this.setState({loadingUnidades: true});
		let proyecto = this.state.proyecto;
		let data = {
			IdProyecto: proyecto.IdProyecto,
		};
		const response = await req.get("proyectos/get/unidades", data);

		if (!response.empty && !response.error) {
			await this.setState({unidades: response.data});
		} else {
			await this.setState({unidades: []});
		}
		this.setState({loadingUnidades: false});
	}

	async loadAreas() {
		let response = await req.get("config/get/areas", {});

		if (!response.error || !response.empty) {
			this.setState({areas: response.areas});
		} else {
			this.setState({areas: []});
		}
		this.setState({loadingAreas: false});
	}

	async loadEquipos() {
		let response = await req.get("config/get/equipos", {});

		if (!response.error || !response.empty) {
			this.setState({equipos: response.equipos});
		} else {
			this.setState({equipos: []});
		}
	}

	// proyectos

	async showAll() {
		await this.setState({
			showProyectos: true,
			showTipos: false,
			showUnidades: false,
			showBack: false,
			proyecto: {},
			newModal: this.openProyect.bind(this),
			title: "",
			page: 1,
		});

		let table = document.getElementById("tableProyectos");
		if (table) {
			table.classList.toggle("animated");
			table.classList.toggle("fadeInLeft");
		}

		this.loadProyectos();
	}

	async setToUpdateProyect(proyecto) {
		await this.setState({
			modalName: "newProyecto",
			proyecto: {
				IdProyecto: proyecto.IdProyecto,
				Nombre: proyecto.Nombre,
				Direccion: proyecto.Direccion,
				Ciudad: proyecto.Ciudad,
				CP: proyecto.CP,
				Ubicacion: proyecto.Ubicacion,
				NoUnidades: proyecto.NoUnidades,
			},
			saveProyecto: this.updateProyecto.bind(this),
		});
		this.openModal();
	}

	async openProyect() {
		await this.setState({modalName: "newProyecto", proyecto: {}, saveProyecto: this.createProyecto.bind(this)});
		this.openModal();
	}

	async createProyecto(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let proyecto = this.state.proyecto;
		let data = {
			Nombre: proyecto.Nombre,
			Direccion: proyecto.Direccion,
			Ciudad: proyecto.Ciudad,
			CP: proyecto.CP,
			Ubicacion: proyecto.Ubicacion,
			NoUnidades: proyecto.NoUnidades,
		};
		const requestApi = await req.post("proyectos/create", data);
		if (requestApi.response) {
			cogoToast.success("Proyecto guardado con éxito.", {position: "top-right"});
			this.setState({proyecto: {}});
			this.closeModal();
			this.loadProyectos();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingModal: false});
	}

	async updateProyecto(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let proyecto = this.state.proyecto;
		let data = {
			IdProyecto: proyecto.IdProyecto,
			Nombre: proyecto.Nombre,
			Direccion: proyecto.Direccion,
			Ciudad: proyecto.Ciudad,
			CP: proyecto.CP,
			Ubicacion: proyecto.Ubicacion,
			NoUnidades: proyecto.NoUnidades,
		};
		const requestApi = await req.post("proyectos/update", data);
		if (requestApi.response) {
			cogoToast.success("Proyecto guardado con éxito.", {position: "top-right"});
			this.setState({proyecto: {}});
			this.closeModal();
			this.loadProyectos();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingModal: false});
	}

	// tipo

	async setProyectosTipo(proyecto) {
		await this.setState({
			showProyectos: false,
			showTipos: true,
			showUnidades: false,
			showBack: true,
			backHandler: this.showAll.bind(this),
			newModal: this.openTipo.bind(this),
			backLabel: "Proyectos",
			proyecto: proyecto,
			title: proyecto.Nombre,
			page: 1,
		});
		this.loadTiposUnidades();
		this.loadAreas();
		this.loadEquipos();

		let table = document.getElementById("tableTipoUnidad");
		if (table) {
			table.classList.toggle("animated");
		}
	}

	async setToUpdateTipo(tipo) {
		await this.setState({
			modalName: "newTipoUnidad",
			tipoUnidad: {
				IdTipoUnidad: tipo.IdTipoUnidad,
				Nombre: tipo.Nombre,
				Observaciones: tipo.Observaciones,
				Metros: tipo.Metros,
				Plano: tipo.Plano,
				TipoUnidadAreas: JSON.parse(tipo.TipoUnidadAreas),
				TipoUnidadEquipos: JSON.parse(tipo.TipoUnidadEquipos),
			},
			saveTipoUnidad: this.updateTipo.bind(this),
		});
		this.openModal();
	}

	async openTipo() {
		await this.setState({modalName: "newTipoUnidad", tipoUnidad: {Nombre: "", Observaciones: "", Metros: "", Plano: ""}, saveTipoUnidad: this.createTipo.bind(this)});
		this.openModal();
	}

	async createTipo(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let tipo = this.state.tipoUnidad;
		let proyecto = this.state.proyecto;
		let data = {
			Nombre: tipo.Nombre,
			Observaciones: tipo.Observaciones,
			Metros: tipo.Metros,
			Plano: tipo.Plano,
			TipoUnidadAreas: tipo.TipoUnidadAreas ? JSON.stringify(tipo.TipoUnidadAreas) : "[]",
			TipoUnidadEquipos: tipo.TipoUnidadEquipos ? JSON.stringify(tipo.TipoUnidadEquipos) : "[]",
			IdProyecto: proyecto.IdProyecto,
		};
		const requestApi = await req.post("proyectos/create/tipo/unidad", data);
		if (requestApi.response) {
			cogoToast.success("Tipo unidad guardado con éxito.", {position: "top-right"});
			this.setState({tipoUnidad: {}});
			this.closeModal();
			this.loadTiposUnidades();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingModal: false});
	}

	async updateTipo(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let tipo = this.state.tipoUnidad;
		let data = {
			IdTipoUnidad: tipo.IdTipoUnidad,
			Nombre: tipo.Nombre,
			Observaciones: tipo.Observaciones,
			Metros: tipo.Metros,
			Plano: tipo.Plano,
			TipoUnidadAreas: JSON.stringify(tipo.TipoUnidadAreas),
			TipoUnidadEquipos: JSON.stringify(tipo.TipoUnidadEquipos),
		};
		const requestApi = await req.post("proyectos/update/tipo/unidad", data);
		if (requestApi.response) {
			cogoToast.success("Tipo unidad guardado con éxito.", {position: "top-right"});
			this.setState({tipoUnidad: {}});
			this.closeModal();
			this.loadTiposUnidades();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingModal: false});
	}

	// unidades

	async setProyectosUnidades(proyecto) {
		await this.setState({
			showProyectos: false,
			showTipos: false,
			showUnidades: true,
			showBack: true,
			backHandler: this.showAll.bind(this),
			newModal: this.openUnidad.bind(this),
			backLabel: "Proyectos",
			proyecto: proyecto,
			saveUnidad: this.createUnidad.bind(this),
			title: proyecto.Nombre,
			page: 1,
		});
		this.loadUnidades();
		this.loadTiposUnidades();

		let table = document.getElementById("tableUnidades");
		if (table) {
			table.classList.toggle("animated");
		}
	}

	async setToUpdateUnidad(unidad) {
		await this.setState({
			modalName: "newUnidad",
			unidad: {IdUnidad: unidad.IdUnidad, Numero: unidad.Numero, Observaciones: unidad.Observaciones, IdTipoUnidad: unidad.IdTipoUnidad},
			saveUnidad: this.updateUnidad.bind(this),
		});
		this.openModal();
	}

	async openUnidad() {
		await this.setState({modalName: "newUnidad", unidad: {Numero: "", Observaciones: "", IdTipoUnidad: ""}});
		this.openModal();
	}

	async createUnidad(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let unidad = this.state.unidad;
		let proyecto = this.state.proyecto;
		let data = {
			Numero: unidad.Numero,
			Observaciones: unidad.Observaciones,
			IdTipoUnidad: unidad.IdTipoUnidad,
			IdProyecto: proyecto.IdProyecto,
		};
		const requestApi = await req.post("proyectos/create/unidad", data);
		if (requestApi.response) {
			cogoToast.success("Unidad guardada con éxito.", {position: "top-right"});
			this.setState({unidad: {}});
			this.closeModal();
			this.loadUnidades();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingModal: false});
	}

	async updateUnidad(event) {
		event.preventDefault();
		this.setState({savingModal: true});
		let unidad = this.state.unidad;
		let {token} = this.props.context.Usuario;
		let data = {
			IdUnidad: unidad.IdUnidad,
			Numero: unidad.Numero,
			Observaciones: unidad.Observaciones,
			IdTipoUnidad: unidad.IdTipoUnidad,
			token: token,
		};
		const requestApi = await req.post("proyectos/update/unidad", data);
		if (requestApi.response) {
			cogoToast.success("Unidad guardada con éxito.", {position: "top-right"});
			this.setState({unidad: {}});
			this.closeModal();
			this.loadUnidades();
		} else {
			cogoToast.error(`Ha ocurrido un error: ${requestApi.message}`, {position: "top-right"});
		}
		this.setState({savingModal: false});
	}

	render() {
		return (
			<div className="column justify-center align-center proyectos">
				<Helmet>
					<title>ELEVA Capital Group | Proyectos</title>
				</Helmet>

				<div className="row full">
					<Sidebar />
					<div className="column align-center full">
						<div className="row">
							<div className="white-space-48"></div>
						</div>
						<div className="row full justify-center wow fadeIn">
							<div className="container justify-start align-center">
								<h3 className="color-secondary">Configuración</h3>
								<h3 className="color-secondary"> &nbsp;/&nbsp;</h3>
								<h4 className="color-darkgray">Proyectos</h4>
								{this.state.title !== "" ? (
									<React.Fragment>
										<h3 className="color-secondary"> &nbsp;/&nbsp;</h3>
										<h4 className="color-darkgray">{this.state.title}</h4>
									</React.Fragment>
								) : null}
							</div>
						</div>
						<div className="row">
							<div className="white-space-8"></div>
						</div>
						<div className="row full justify-center">
							<div className="column full">
								<div className="row">
									<div className="white-space-8"></div>
								</div>
								<div className="row justify-center align-center">
									<Controls
										newHandler={this.state.newModal}
										showBack={this.state.showBack}
										backHandler={this.state.backHandler}
										backLabel={this.state.backLabel}
										showFilter={true}
									/>
								</div>
								<div className="row">
									<div className="white-space-16"></div>
								</div>
								<div className="row full justify-center">
									<div className="container">
										<div className="column card-table full align-center">
											{this.state.showProyectos ? (
												<TableProyectos
													loadingProyectos={this.state.loadingProyectos}
													loadProyectos={this.loadProyectos.bind(this)}
													proyectos={this.state.proyectos}
													setProyectos={this.setProyectosTipo.bind(this)}
													setProyectosUnidades={this.setProyectosUnidades.bind(this)}
													setToUpdateProyect={this.setToUpdateProyect.bind(this)}
													page={this.state.page}
												/>
											) : this.state.showTipos ? (
												<TableTipoUnidad
													loadingTipos={this.state.loadingTipos}
													loadTiposUnidades={this.loadTiposUnidades.bind(this)}
													tipos={this.state.tipos}
													setToUpdateTipo={this.setToUpdateTipo.bind(this)}
													page={this.state.page}
												/>
											) : this.state.showUnidades ? (
												<TableUnidades
													loadingUnidades={this.state.loadingUnidades}
													loadUnidades={this.loadUnidades.bind(this)}
													unidades={this.state.unidades}
													setToUpdateUnidad={this.setToUpdateUnidad.bind(this)}
													page={this.state.page}
												/>
											) : null}
											<div className="row">
												<div className="white-space-16"></div>
											</div>
											<div className="row justify-center align-center">
												<Paginator
													pages={Math.ceil(
														(this.state.showProyectos
															? this.state.proyectos.length
															: this.state.showTipos
															? this.state.tipos.length
															: this.state.showUnidades
															? this.state.unidades.length
															: 0) / 15
													)}
													setPage={this.selectPage.bind(this)}
												/>
											</div>
											<div className="row">
												<div className="white-space-16"></div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="white-space-16"></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<DinamicModal
					idModal={"newProyecto"}
					sizeModalContainer={"mini"}
					title={"Proyecto"}
					btnTextSuccess={"Guardar"}
					success={this.state.saveProyecto}
					showBtnSuccess={true}
					btnTextCancel={"Cancelar"}
					showBtnCancel={true}
					loadingSave={this.state.savingModal}
				>
					<ModalProyecto updateJson={this.updateJson.bind(this)} proyecto={this.state.proyecto} />
				</DinamicModal>

				<DinamicModal
					idModal={"newTipoUnidad"}
					sizeModalContainer={"normal"}
					title={"Tipo Unidad"}
					btnTextSuccess={"Guardar"}
					success={this.state.saveTipoUnidad}
					showBtnSuccess={true}
					btnTextCancel={"Cancelar"}
					showBtnCancel={true}
					loadingSave={this.state.savingModal}
				>
					<ModalTipoUnidad updateJson={this.updateJson.bind(this)} tipoUnidad={this.state.tipoUnidad} areas={this.state.areas} equipos={this.state.equipos} />
				</DinamicModal>

				<DinamicModal
					idModal={"newUnidad"}
					sizeModalContainer={"mini"}
					title={"Unidad"}
					btnTextSuccess={"Guardar"}
					success={this.state.saveUnidad}
					showBtnSuccess={true}
					btnTextCancel={"Cancelar"}
					showBtnCancel={true}
					loadingSave={this.state.savingModal}
				>
					<ModalUnidad updateJson={this.updateJson.bind(this)} unidad={this.state.unidad} tipos={this.state.tipos} />
				</DinamicModal>
			</div>
		);
	}
}
export default withRouter(Consumer(Proyectos));

import React, {Component} from "react";
import Views from "./core/views";

class App extends Component {
	render() {
		return (
			<div className="App">
				<Views> </Views>
			</div>
		);
	}
}

export default App;

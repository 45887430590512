import React from "react";
import Helmet from "react-helmet";
import {withRouter} from "react-router-dom";
import ImageLoader from "react-loading-image";
import Chart from "chart.js";
import moment from "moment-timezone";
import WOW from "wowjs";

import Request from "../core/httpClient";
import {Consumer} from "../context";
import Sidebar from "../components/Sidebar";
import Paginator from "../components/Paginator";
import DinamicModal from "../components/modal/DinamicModal";

import NoAvalible from "../images/no-image-available.png";
import Loading from "../images/load.gif";
import ModalSolicitud from "../components/solicitud/modal/modalSolicitud";

const req = new Request();

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageNew: 1,
			pageVisitas: 1,
			loadingDashboard: false,
			agendadas: [],
			nuevas: [],
			aplicadas: 0,
			noAplicadas: 0,
			solicitud: {},
			areas: [],
			equipos: [],
			problemas: [],
			estados: [],
			proyectos: [],
			unidades: [],
			solicitud: {},
			modalName: "modalSolicitud",
			dataGraph: [],
			labelGraph: [],
			propietarios: []
		};
	}

	async componentDidMount() {
		new WOW.WOW({live: false}).init();
		this.loadAreas();
		this.loadProblemas();
		this.loadEquipos();
		this.loadEstados();
		this.loadProyectos();
		this.loadUnidades();
		this.loadPropietarios();
		await this.loadDataDashboard();
		this.chartInit();
	}

	// loads
	async loadDataDashboard() {
		this.setState({loadingDashboard: true});
		let reponseApi = await req.get("solicitud/get/dashboard", {});
		if (!reponseApi.error) {
			this.setState({
				agendadas: reponseApi.agendadas || [],
				nuevas: reponseApi.nuevas || [],
				aplicadas: reponseApi.aplicadas || [],
				noAplicadas: reponseApi.noAplicadas || [],
				dataGraph: reponseApi.dataGraph || [],
				labelGraph: reponseApi.labelGraph || [],
			});
		} else {
			this.setState({
				agendadas: [],
				nuevas: [],
				aplicadas: 0,
				noAplicadas: 0,
				dataGraph: [],
				labelGraph: [],
			});
		}
		this.setState({loadingDashboard: false});
	}

	async loadAreas() {
		this.setState({loadingAreas: true});
		let response = await req.get("config/get/areas", {});

		if (!response.error || !response.empty) {
			this.setState({areas: response.areas});
		} else {
			this.setState({areas: []});
		}
		this.setState({loadingAreas: false});
	}

	async loadEquipos() {
		this.setState({loadingEquipos: true});
		let response = await req.get("config/get/equipos", {});

		if (!response.error || !response.empty) {
			this.setState({equipos: response.equipos});
		} else {
			this.setState({equipos: []});
		}
		this.setState({loadingEquipos: false});
	}

	async loadProblemas() {
		this.setState({loadingProblemas: true});
		let response = await req.get("config/get/problemas", {});
		if (!response.error || !response.empty) {
			this.setState({problemas: response.problemas});
		} else {
			this.setState({problemas: []});
		}
		this.setState({loadingProblemas: false});
	}

	async loadEstados() {
		this.setState({loadingEstados: true});
		let response = await req.get("config/get/estados", {});
		if (!response.error || !response.empty) {
			this.setState({estados: response.estados ? response.estados.filter((edo) => edo.IdEstado !== 2) : []});
		} else {
			this.setState({estados: []});
		}
		this.setState({loadingEstados: false});
	}

	async loadProyectos() {
		this.setState({loadingProyectos: true});
		const response = await req.get("proyectos/get", {});
		if (!response.empty && !response.error) {
			this.setState({proyectos: response.data});
		} else {
			this.setState({proyectos: []});
		}
		this.setState({loadingProyectos: false});
	}

	async loadUnidades() {
		this.setState({loadingUnidades: true});
		const response = await req.get("proyectos/get/all/unidades", {});
		if (!response.empty && !response.error) {
			this.setState({unidades: response.data});
		} else {
			this.setState({unidades: []});
		}
		this.setState({loadingUnidades: false});
	}

	async loadPropietarios() {
		this.setState({loadingPropietarios: true});
		const apiRequest = await req.get("propietarios/get/all", {});
		if (!apiRequest.error) {
			this.setState({propietarios: apiRequest.data ? apiRequest.data : []});
		} else {
			this.setState({propietarios: []});
		}
		this.setState({loadingPropietarios: false});
	}
	// generales
	async selectPageNew(page) {
		this.setState({pageNew: page});
	}

	async selectPageVisitas(page) {
		this.setState({pageVisitas: page});
	}

	async openModal() {
		let modal = document.getElementById(this.state.modalName);

		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	async setSolicitud(solicitud) {
		this.setState({solicitud: solicitud});
		this.openModal();
	}

	async chartInit() {
		let ctx = document.getElementById("barChart");
		new Chart(ctx, {
			type: "bar",
			data: {
				labels: this.state.labelGraph,
				datasets: [
					{
						data: this.state.dataGraph,
						borderWidth: 0, // borde de barras
						pointHitRadius: 3,
						pointBorderWidth: 3,
						backgroundColor: "#4b4b4bc7",
						hoverBackgroundColor: "#CFA868c7",
						barPercentage: 0.5,
					},
				],
			},
			options: {
				scales: {
					yAxes: [
						{
							stacked: true,
						},
					],
				},
				title: {
					display: true,
					text: "Solicitudes por día",
				},
				legend: {
					display: false,
				},
			},
		});
	}

	render() {
		return (
			<div className="column justify-center align-center">
				<Helmet>
					<title>ELEVA Capital Group | Dashboard</title>
				</Helmet>
				<div className="row full dashboard">
					<Sidebar />
					<div className="column align-center full">
						<div className="row">
							<div className="white-space-48"></div>
						</div>
						<div className="row full justify-center">
							<div className="container justify-start align-center">
								<h3 className="color-secondary">Dashboard</h3>
							</div>
						</div>
						<div className="row full justify-center">
							<div className="column full">
								<div className="row-responsive justify-center align-center full ">
									<div className="container">
										<div className="column full padding-right-small wow fadeIn">
											<div className="card">
												<div className="row">
													<div className="white-space-16"></div>
												</div>
												<div className="row full">
													<h3>Últimas solicitudes</h3>
												</div>
												<div className="row">
													<div className="white-space-16"></div>
												</div>
												{this.state.nuevas.slice((this.state.pageNew - 1) * 9, this.state.pageNew * 9).map((sol, key) => (
													<div key={key} className="row  row-solicitud">
														<div className="column half justify-center align-center">
															<div className="responsive-img img-solicitud">
																<ImageLoader
																	src={sol.Img1}
																	image={() => <img src={sol.Img1} alt="Producto" />}
																	loading={() => <img src={Loading} alt="Cargando..." />}
																	error={() => <img src={NoAvalible} alt="Cargando..." />}
																/>
															</div>
														</div>
														<div className="column full justify-center align-start">
															<span className="font-regular weight-medium">{sol.Numero}</span>
															<div className="white-space-8"></div>
															<span className="font-mini color-gray">{sol.Comentarios}</span>
														</div>
														<div className="column half justify-center align-center">
															<span>{sol.Estado}</span>
														</div>
														<div className="column half justify-center align-center">
															<button
																className="btn btn-empty btn-small color-white"
																onClick={(event) => {
																	this.setSolicitud(sol);
																}}
															>
																<i className="fas fa-info-circle"></i>
															</button>
														</div>
													</div>
												))}
												<div className="row">
													<div className="white-space-16"></div>
												</div>
												<Paginator pages={Math.ceil(this.state.nuevas.length / 9)} setPage={this.selectPageNew.bind(this)} />
											</div>
											<div className="row">
												<div className="white-space-16"></div>
											</div>
										</div>
										<div className="column half wow fadeIn">
											<div className="card">
												<div className="row">
													<div className="white-space-16"></div>
												</div>
												<div className="row full">
													<h3>Proximas visitas</h3>
												</div>
												<div className="row">
													<div className="white-space-16"></div>
												</div>
												{this.state.agendadas.slice((this.state.pageVisitas - 1) * 10, this.state.pageVisitas * 10).map((ag, key) => (
													<div key={key} className="row row-solicitud">
														<div className="column full justify-center align-start">
															<span className="font-regular weight-medium">{ag.Numero}</span>
														</div>
														<div className="column full justify-center align-center">
															<span>{ag.FechaVisita ? moment(ag.FechaVisita).format("DD-MM-YYYY HH:mm") : ""}</span>
														</div>
														<div className="column full justify-center align-center">
															<button
																className="btn btn-empty btn-small color-white"
																onClick={(event) => {
																	this.setSolicitud(ag);
																}}
															>
																<i className="fas fa-info-circle"></i>
															</button>
														</div>
													</div>
												))}

												<div className="row">
													<div className="white-space-16"></div>
												</div>
												<Paginator pages={Math.ceil(this.state.agendadas.length / 10)} setPage={this.selectPageVisitas.bind(this)} />
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="white-space-16"></div>
								</div>
								<div className="row full justify-center wow fadeIn">
									<div className="container justify-center align-center"></div>
								</div>
								<div className="row">
									<div className="white-space-16"></div>
								</div>
								<div className="row justify-center align-center wow fadeIn">
									<div className="container">
										<div className="column half">
											<div className="row">
												<div className="column full padding-right-small">
													<div className="card-stats justify-center align-center">
														<div className="row">
															<div className="white-space-8"></div>
														</div>
														<div className="row full justify-center">
															<span className="font-huge weight-bold">{this.state.nuevas ? this.state.nuevas.length : 0}</span>
														</div>
														<div className="row">
															<div className="white-space-8"></div>
														</div>
														<div className="row full justify-center">
															<span className="font-text color-gray">Solicitudes Nuevas</span>
														</div>
														<div className="row">
															<div className="white-space-8"></div>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="column full padding-right-small">
													<div className="card-stats justify-center align-center">
														<div className="row">
															<div className="white-space-16"></div>
														</div>
														<div className="row full justify-center">
															<span className="font-huge weight-bold">{this.state.aplicadas}</span>
														</div>
														<div className="row">
															<div className="white-space-8"></div>
														</div>
														<div className="row full justify-center">
															<span className="font-text color-gray">Garantías aplicadas</span>
														</div>
														<div className="row">
															<div className="white-space-8"></div>
														</div>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="column full padding-right-small">
													<div className="card-stats justify-center align-center">
														<div className="row">
															<div className="white-space-16"></div>
														</div>
														<div className="row full justify-center">
															<span className="font-huge weight-bold">{this.state.noAplicadas}</span>
														</div>
														<div className="row">
															<div className="white-space-8"></div>
														</div>
														<div className="row full justify-center">
															<span className="font-text color-gray">Garantías no aplicadas</span>
														</div>
														<div className="row">
															<div className="white-space-8"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="column chart-big full">
											<canvas className="pie" id="barChart" width="50%" height="20%"></canvas>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="white-space-16"></div>
								</div>

								<div className="row">
									<div className="white-space-16"></div>
								</div>
								<DinamicModal
									idModal={"modalSolicitud"}
									sizeModalContainer={"normal"}
									title={"Reportes"}
									btnTextSuccess={"Guardar"}
									success={() => {}}
									showBtnSuccess={false}
									btnTextCancel={"Cancelar"}
									showBtnCancel={true}
									loadingSave={this.state.savingModal}
								>
									<ModalSolicitud
										updateJson={() => {}}
										solicitud={this.state.solicitud}
										areas={this.state.areas}
										equipos={this.state.equipos}
										problemas={this.state.problemas}
										estados={this.state.estados}
										proyectos={this.state.proyectos}
										unidades={this.state.unidades}
										newMode={this.state.newMode}
										propietarios={this.state.propietarios}
									/>
								</DinamicModal>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(Consumer(Home));

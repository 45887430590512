import React, {useState, useEffect, useRef} from "react";
import moment from "moment-timezone";
import ImageLoader from "react-loading-image";
import {Link} from "react-router-dom";
import CogoToast from 'cogo-toast';

import ModalZoom from "../../modal/modalZoom";
import NoAvalible from "../../../images/no-image-available.png";
import Loading from "../../../images/load.gif";
import ImageUploader from "../../imagesUpload/images-upload";

const ModalSolicitud = (props) => {
	const [solicitud, setSolicitud] = useState({});
	const [problemas, setProblemas] = useState([]);
	const [areas, setAreas] = useState([]);
	const [equipos, setEquipos] = useState([]);
	const [estados, setEstados] = useState([]);
	const [proyectos, setProyectos] = useState([]);
	const [unidades, setUnidades] = useState([]);
	const [propietarios, setPropietarios] = useState([]);
	const [newMode, setNewMode] = useState(false);
	const [imgZoom, setImgZoom] = useState("");
	const [pictures, setPictures] = useState([]);
	const [defaultPictures, setDefaultPictures] = useState([]);
	const [realizado, setRealizado] = useState(false);
	const [ImageUploaderKey, setImageUploaderKey] = useState(0);

	useEffect(() => {
		setSolicitud(props.solicitud);
		setProblemas(props.problemas);
		setAreas(props.areas);
		setEquipos(props.equipos);
		setEstados(props.estados);
		setProyectos(props.proyectos);
		setUnidades(props.unidades);
		setPropietarios(props.propietarios);
		setNewMode(props.newMode);
		setImageUploaderKey(ImageUploaderKey + 1);
		setPictures([])
	}, [props.solicitud, props.problemas, props.areas, props.equipos, props.estados, props.proyectos, props.unidades, props.newMode]);

	useEffect(() => {
		const { ImgEvidencia1, ImgEvidencia2, ImgEvidencia3 } = solicitud;
		let evidenciasPics = [];
		if (ImgEvidencia1) {
			evidenciasPics.push(ImgEvidencia1);
		}
		if (ImgEvidencia2) {
			evidenciasPics.push(ImgEvidencia2);
		}
		if (ImgEvidencia3) {
			evidenciasPics.push(ImgEvidencia3);
		}

		setDefaultPictures(evidenciasPics);

	}, [solicitud])

	function updateSolicitud(slc) {
		if (!slc.pictures) {
			slc.pictures = pictures;
		}

		slc.realizado = realizado;

		props.updateJson({solicitud: slc});
	}

	const hiddenFileInput = useRef(null);
	const hiddenFileInput2 = useRef(null);
	const hiddenFileInput3 = useRef(null);

	function openZoom(img) {
		setImgZoom(img);
		let modal = document.getElementById("image-zoom-modal");
		if (modal) {
			modal.classList.remove("hide-zoom-modal");
			modal.classList.add("show-zoom-modal");
		}
	}

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	async function changeImg(idImg, event) {
		let file = event.target;
		if (file) {
			if (file.files[0]) {
				let sol = solicitud;
				if (idImg === 1) {
					sol.Img1 = await toBase64(file.files[0]);
					sol.File1 = file.files[0];
				} else if (idImg === 2) {
					sol.Img2 = await toBase64(file.files[0]);
					sol.File2 = file.files[0];
				} else if (idImg === 3) {
					sol.Img3 = await toBase64(file.files[0]);
					sol.File3 = file.files[0];
				}
				await updateSolicitud(sol);
			}
		}
	}

	const onDrop = (picture) => {
		setPictures(picture);
		const slc = solicitud;
		slc.pictures = picture;
		updateSolicitud(slc);
	};

	return (
		<React.Fragment>
			<div className="worko-tabs">
				<input className="state" type="radio" title="tab-one" name="tabs-state" id="tab-one" defaultChecked />
				<input className="state" type="radio" title="tab-two" name="tabs-state" id="tab-two" />
				<input className="state" type="radio" title="tab-three" name="tabs-state" id="tab-three" />

				<div className="tabs flex-tabs">
					<label htmlFor="tab-one" id="tab-one-label" className="tab">
						General
					</label>
					<label htmlFor="tab-two" id="tab-two-label" className="tab">
						Garantía
					</label>
					<label htmlFor="tab-three" id="tab-three-label" className="tab">
						Realizado
					</label>
					<div id="tab-one-panel" className="panel active">
						<div className="full row align-center">
							<div className="column full">
								<div className="white-space-8"></div>
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>No Solicitud:</b>
										</p>
									</div>
									<div className="column column-small">
										<input
											type="text"
											className="input input-modals"
											value={solicitud.NoSolicitud ? solicitud.NoSolicitud : ""}
											autoComplete="off"
											disabled
											minLength={2}
											required
										/>
									</div>
									<div className="column label-middle">
										<p>
											<b>Fecha:</b>
										</p>
									</div>
									<div className="column column-small">
										<input
											type="date"
											className="input input-modals"
											required
											autoComplete="off"
											disabled={!newMode}
											value={solicitud.Fecha ? moment(solicitud.Fecha).utc().format("YYYY-MM-DD") : ""}
											onChange={(event) => {
												let sol = solicitud;
												sol.Fecha = event.target.value;
												updateSolicitud(sol);
											}}
										/>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Proyecto:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdProyecto ? solicitud.IdProyecto : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdProyecto = event.target.value;
												sol.IdPersona = "";
												sol.IdUnidad = "";
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Proyecto</option>
											{proyectos.map((pry, key) => (
												<option key={key} value={pry.IdProyecto}>
													{pry.Nombre}
												</option>
											))}
										</select>
									</div>
									<div className="column label-middle">
										<p>
											<b>Unidad:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdUnidad ? solicitud.IdUnidad : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdUnidad = event.target.value;
												sol.IdPersona = "";
												updateSolicitud(sol);
											}}
										>
											<option value="">Elige Unidad</option>
											{unidades
												.filter((un) => (solicitud.IdProyecto ? solicitud.IdProyecto == un.IdProyecto : true))
												.map((unidad, key) => (
													<option key={key} value={unidad.IdUnidad}>
														{unidad.Numero}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Reportó:</b>
										</p>
									</div>
									<div className="column full">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdPersona ? solicitud.IdPersona : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdPersona = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Nombre</option>
											{propietarios
												.filter((pr) => (solicitud.IdUnidad ? solicitud.IdUnidad == pr.IdUnidad : false))
												.map((pr, key) => (
													<option key={key} value={pr.IdPersona}>
														{pr.Nombre}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Área:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdArea ? solicitud.IdArea : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdArea = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Área</option>
											{areas.map((area, key) => (
												<option key={key} value={area.IdArea}>
													{area.NombreArea}
												</option>
											))}
										</select>
									</div>
									<div className="column label-middle">
										<p>
											<b>Equipo:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											required
											value={solicitud.IdEquipo ? solicitud.IdEquipo : ""}
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdEquipo = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Equipo</option>
											{equipos.map((equipo, key) => (
												<option key={key} value={equipo.IdEquipo}>
													{equipo.NombreEquipo}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Detalle:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdProblema ? solicitud.IdProblema : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdProblema = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Detalle</option>
											{problemas.map((problema, key) => (
												<option key={key} value={problema.IdProblema}>
													{problema.NombreProblema}
												</option>
											))}
										</select>
									</div>
									<div className="column label-middle">
										<p>
											<b>Fecha visita:</b>
										</p>
									</div>
									<div className="column column-small">
										<input className="input" type="datetime-local" disabled value={solicitud.FechaVisita ? moment(solicitud.FechaVisita).format("YYYY-MM-DDTHH:mm:ss") : ""} />
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Comentarios Inquilino:</b>
										</p>
									</div>
									<div className="column full">
										<textarea
											className="input"
											value={solicitud.Comentarios ? solicitud.Comentarios : ""}
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.Comentarios = event.target.value;
												updateSolicitud(sol);
											}}
										></textarea>
									</div>
								</div>
								<div className="row full align-center">
									<div className="column full column-image justify-center align-center img-update">
										<div className="container-img justify-center align-center">
											<ImageLoader
												src={solicitud.Img1}
												image={() => <img src={solicitud.Img1} alt="Producto" />}
												loading={() => <img src={Loading} alt="Cargando..." />}
												error={() => <img src={NoAvalible} alt="Cargando..." />}
											/>
											<div className="middle-overlay">
												<label className="btn-upload-image column align-center justify-center">
													<Link
														to="#"
														onClick={() => {
															openZoom(solicitud.Img1);
														}}
													>
														<i className="fas fa-eye"> &nbsp; Ver</i>
													</Link>
													{!solicitud.IdSolicitud ? (
														<React.Fragment>
															<div className="white-space-8"></div>

															<Link
																to="#"
																onClick={() => {
																	hiddenFileInput.current.click();
																}}
															>
																<i className="fas fa-file-upload"> &nbsp; Subir </i>
															</Link>
															<input type="file" ref={hiddenFileInput} id="img1" accept="image/*" onChange={changeImg.bind(this, 1)} />
														</React.Fragment>
													) : null}
												</label>
											</div>
										</div>
									</div>
									<div className="column full column-image justify-center align-center img-update">
										<div className="container-img justify-center align-center">
											<ImageLoader
												src={solicitud.Img2}
												image={() => <img src={solicitud.Img2} alt="Producto" />}
												loading={() => <img src={Loading} alt="Cargando..." />}
												error={() => <img src={NoAvalible} alt="Cargando..." />}
											/>
											<div className="middle-overlay">
												<label className="btn-upload-image column align-center justify-center">
													<Link
														to="#"
														onClick={() => {
															openZoom(solicitud.Img2);
														}}
													>
														<i className="fas fa-eye"> &nbsp; Ver</i>
													</Link>
													{!solicitud.IdSolicitud ? (
														<React.Fragment>
															<div className="white-space-8"></div>

															<Link
																to="#"
																onClick={() => {
																	hiddenFileInput2.current.click();
																}}
															>
																<i className="fas fa-file-upload"> &nbsp; Subir </i>
															</Link>
															<input type="file" ref={hiddenFileInput2} id="img2" accept="image/*" onChange={changeImg.bind(this, 2)} />
														</React.Fragment>
													) : null}
												</label>
											</div>
										</div>
									</div>
									<div className="column full column-image justify-center align-center img-update">
										<div className="container-img justify-center align-center">
											<ImageLoader
												src={solicitud.Img3}
												image={() => <img src={solicitud.Img3} alt="Producto" />}
												loading={() => <img src={Loading} alt="Cargando..." />}
												error={() => <img src={NoAvalible} alt="Cargando..." />}
											/>
											<div className="middle-overlay">
												<label className="btn-upload-image column align-center justify-center">
													<Link
														to="#"
														onClick={() => {
															openZoom(solicitud.Img3);
														}}
													>
														<i className="fas fa-eye"> &nbsp; Ver</i>
													</Link>
													{!solicitud.IdSolicitud ? (
														<React.Fragment>
															<div className="white-space-8"></div>

															<Link
																to="#"
																onClick={() => {
																	hiddenFileInput3.current.click();
																}}
															>
																<i className="fas fa-file-upload"> &nbsp; Subir </i>
															</Link>
															<input type="file" ref={hiddenFileInput3} id="img3" accept="image/*" onChange={changeImg.bind(this, 3)} />
														</React.Fragment>
													) : null}
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="tab-two-panel" className="panel">
						<div className="full row align-center">
							<div className="column full">
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Aplica Garantía:</b>
										</p>
									</div>
									<div className="column column-small">
										<label className="container-check">
											<input
												type="checkbox"
												checked={solicitud.AplicaGarantia ? solicitud.AplicaGarantia : false}
												onClick={() => {
													let sol = solicitud;
													sol.AplicaGarantia = !sol.AplicaGarantia;
													updateSolicitud(sol);
												}}
												readOnly
											/>
											<span className="checkmark"></span>
										</label>
									</div>
									<div className="column label-middle">
										<p>
											<b>Estado Solicitud:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdEstado ? solicitud.IdEstado : ""}
											required
											onChange={(event) => {
												let sol = solicitud;
												sol.IdEstado = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Estado</option>
											{estados.map((edo, key) => (
												<option key={key} value={edo.IdEstado}>
													{edo.Estado}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Comentario:</b>
										</p>
									</div>
									<div className="column full">
										<textarea
											className="input"
											value={solicitud.ComentariosAplica ? solicitud.ComentariosAplica : ""}
											onChange={(event) => {
												let sol = solicitud;
												sol.ComentariosAplica = event.target.value;
												updateSolicitud(sol);
											}}
										></textarea>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center justify-center">
									<div className="column-middle label-normal justify-center">
										<p>
											<b>Fecha 1:</b>
										</p>
									</div>
									<div className="column-middle label-normal  justify-center">
										<p>
											<b>Fecha 2:</b>
										</p>
									</div>
									<div className="column-middle label-normal  justify-center">
										<p>
											<b>Fecha 3:</b>
										</p>
									</div>
								</div>
								<div className="row full align-center justify-center">
									<div className="column-middle justify-center">
										<input
											className="input input-huge"
											type="datetime-local"
											value={solicitud.Fecha1 ? moment(solicitud.Fecha1).format("YYYY-MM-DDTHH:mm:ss") : ""}
											disabled={solicitud.AplicaGarantia ? !solicitud.AplicaGarantia : true}
											required
											onChange={(event) => {
												let sol = solicitud;
												sol.Fecha1 = event.target.value;
												updateSolicitud(sol);
											}}
										/>
									</div>
									<div className="column-middle justify-center">
										<input
											className="input input-huge"
											type="datetime-local"
											disabled={solicitud.AplicaGarantia ? !solicitud.AplicaGarantia : true}
											value={solicitud.Fecha2 ? moment(solicitud.Fecha2).format("YYYY-MM-DDTHH:mm:ss") : ""}
											onChange={(event) => {
												let sol = solicitud;
												sol.Fecha2 = event.target.value;
												updateSolicitud(sol);
											}}
										/>
									</div>
									<div className="column-middle justify-center">
										<input
											className="input input-huge"
											type="datetime-local"
											disabled={solicitud.AplicaGarantia ? !solicitud.AplicaGarantia : true}
											value={solicitud.Fecha3 ? moment(solicitud.Fecha3).format("YYYY-MM-DDTHH:mm:ss") : ""}
											onChange={(event) => {
												let sol = solicitud;
												sol.Fecha3 = event.target.value;
												updateSolicitud(sol);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="tab-three-panel" className="panel">
						<div className="row full">
							<div className="column label-normal">
								<p>
									<b>Marcar realizado:</b>
								</p>
							</div>
							<div className="column column-small">
								<label className="container-check">
									<input
										type="checkbox"
										checked={realizado? true : false}
										readOnly
										onClick={() => {
											setRealizado(!realizado);
											const slc = solicitud;
											slc.realizado = !realizado;
											updateSolicitud(slc);
										}}
									/>
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
						<div className="upload-images">
							<ImageUploader
								key				=	{ImageUploaderKey}
								defaultPictures =	{defaultPictures}
								onChange		=	{onDrop.bind(this)}
							/>
						</div>
					</div>
				</div>
			</div>
			<ModalZoom
				imgZoom={imgZoom}
				closeModal={(value) => {
					setImgZoom(value);
				}}
			/>
		</React.Fragment>
	);
};
export default ModalSolicitud;

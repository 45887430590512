import React, {useEffect, useState} from "react";

const TableDocumentos = (props) => {
	const [documentos, setDocumentos] = useState([]);
	const [loadingDocumentos, setLoadingDocumentos] = useState(false);

	useEffect(() => {
		setDocumentos(props.documentos);
		setLoadingDocumentos(props.loadingDocumentos);
	}, [props.documentos, props.loadingDocumentos]);

	return (
		<div className="table-responsive full">
			<table>
				<thead>
					<tr>
						<th className="text-left">Nombre Documento</th>
						<th className="text-center th-md"></th>
						<th className="text-center th-md"></th>
					</tr>
				</thead>
				<tbody>
					{loadingDocumentos ? (
						<tr>
							<td className="text-center" colSpan="3">
								<i className="fas fa-spinner fa-spin"></i>
							</td>
						</tr>
					) : documentos ? (
						documentos.length == 0 ? (
							<tr>
								<td className="text-center" colSpan="3">
									Sin datos que mostrar
								</td>
							</tr>
						) : (
							documentos.slice((props.page - 1) * 15, props.page * 15).map((documento, key) => (
								<tr key={key}>
									<td className="">{documento.NombreDocumento}</td>
									<td className="text-center">
										<button
											type="button"
											className=" btn-small btn-empty"
											onClick={() => {
												props.viewDocument(documento);
											}}
										>
											<i className="fas fa-eye"></i>
										</button>
									</td>
									<td className="text-center">
										<button
											className=" btn-small btn-empty"
											type="button"
											onClick={() => {
												props.setDeleteDocument(documento);
											}}
										>
											<i className="font-small fas fa-trash-alt" />
										</button>
									</td>
								</tr>
							))
						)
					) : null}
				</tbody>
			</table>
		</div>
	);
};
export default TableDocumentos;

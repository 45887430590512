import React, {useEffect, useState} from "react";
import cogoToast from "cogo-toast";
import {Consumer} from "../../../context";
import Request from "../../../core/httpClient";
import Paginator from "../../Paginator";
import DinamicModal from "../../modal/DinamicModal";
import DeleteModal from "../../modal/DeleteModal";
import ModalCatalogo from "../modal/modalCatalogo";

const req = new Request();

const TableAreas = (props) => {
	const [areas, setAreas] = useState([]);
	const [savingModal, setSavingModal] = useState(false);
	const [currentArea, setCurrentArea] = useState({});
	const [page, setPage] = useState(1);

	useEffect(() => {
		setAreas(props.areas);
	}, [props.areas]);

	async function selectPage(page) {
		setPage(page);
	}

	async function openModal(area, id) {
		let modal = document.getElementById(id);
		setCurrentArea(area);
		if (modal) {
			modal.classList.remove("hide-dinamic-modal");
			modal.classList.add("show-modal");
		}
	}

	async function updateArea(event) {
		event.preventDefault();
		setSavingModal(true);
		const apiRequest = await req.post("config/update/area", currentArea);
		if (apiRequest.response) {
			cogoToast.success("Área guardada con éxito.", {position: "top-right"});
			closeModal("editArea");
			props.loadAreas();
			setCurrentArea({});
		} else {
			cogoToast.error(`Ha ocurrido un error: ${apiRequest.message}`, {position: "top-right"});
		}
		setSavingModal(false);
	}

	async function deleteArea(event) {
		event.preventDefault();
		setSavingModal(true);
		let data = {IdArea: currentArea.IdArea};
		const apiRequest = await req.post("config/delete/area", data);
		if (apiRequest.response) {
			cogoToast.success("Área eliminada con éxito.", {position: "top-right"});
			closeModal("deleteModal");
			props.loadAreas();
			setCurrentArea({});
		} else {
			cogoToast.error(`Ha ocurrido un error: ${apiRequest.message}`, {position: "top-right"});
		}
		setSavingModal(false);
	}

	async function closeModal(id) {
		let modal = document.getElementById(id);
		if (modal) {
			modal.classList.add("hide-dinamic-modal");
			modal.classList.remove("show-dinamic-modal");
		}
	}

	async function updateValue(value) {
		setCurrentArea({...currentArea, NombreArea: value});
	}

	return (
		<React.Fragment>
			{
				<div className="table-responsive full">
					<table>
						<thead>
							<tr>
								<th className="text-left">Áreas</th>
								<th className="text-center th-lg"></th>
								<th className="text-center th-lg"></th>
							</tr>
						</thead>
						<tbody>
							{props.loadingAreas ? (
								<tr>
									<td className="text-center" colSpan="3">
										<i className="fas fa-spinner fa-spin"></i>
									</td>
								</tr>
							) : (
								areas.slice((page - 1) * 15, page * 15).map((area, key) => (
									<tr key={key}>
										<td>{area.NombreArea}</td>
										<td className="text-center">
											<button
												className="btn-small btn-empty"
												onClick={() => {
													openModal(area, "editArea");
												}}
											>
												<i className="font-small fas fa-edit" />
											</button>
										</td>
										<td className="text-center">
											<button
												className="btn-small btn-empty"
												onClick={() => {
													openModal(area, "deleteModal");
												}}
											>
												<i className="font-small fas fa-trash-alt" />
											</button>
										</td>
									</tr>
								))
							)}
						</tbody>
					</table>
				</div>
			}
			<div className="row">
				<div className="white-space-16"></div>
			</div>
			<div className="row justify-center align-center">
				<Paginator pages={Math.ceil(areas.length / 15)} setPage={selectPage.bind(this)} />
			</div>
			<DinamicModal
				idModal={"editArea"}
				sizeModalContainer={"mini"}
				title={"Editar área"}
				btnTextSuccess={"Guardar"}
				success={updateArea}
				showBtnSuccess={true}
				btnTextCancel={"Cancelar"}
				showBtnCancel={true}
				loadingSave={savingModal}
			>
				<ModalCatalogo value={currentArea.NombreArea} updateValue={updateValue.bind(this)} />
			</DinamicModal>
			<DeleteModal id="deleteModal" title="Eliminar" message="¿Está seguro de eliminar el área?" acceptMethod={deleteArea} savingModal={savingModal} />
		</React.Fragment>
	);
};
export default Consumer(TableAreas);

import React from "react";
import Helmet from "react-helmet";
import {withRouter, Link} from "react-router-dom";

import {Consumer} from "../context";
import Request from "../core/httpClient";
import Logo from "../images/logo.png";

const req = new Request();

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: "",
			errorPass: false,
			errorMessage: "",
			successRecover: false,
			render: "usuario",
		};
		if (this.props.match.params.id) {
			this.verifyToken(this.props.match.params.id);
		}
	}

	async recover(e) {
		e.preventDefault();
		this.setState({loading: true});
		const form = e.target;
		const data = {
			Email: form.usuario.value || "",
		};

		const res = await req.post("app/users/recuperar/password", data);
		if (res.error) {
			this.setState({errorPass: true, errorMessage: "Usuario incorrecto"});
		} else {
			{
				this.setState({errorMessage: "Recibirá un correo de confirmación en breve.", successRecover: true, errorPass: false, loading: false});
			}
		}
		this.setState({loading: false});
	}

	async changePasword(e) {
		e.preventDefault();
		this.setState({loading: true});
		const form = e.target;
		const data = {
			token: this.state.token,
			password: form.password.value || "",
		};
		const res = await req.post("app/users/update/recover/password", data);
		if (res.error) {
			this.setState({errorPass: true, errorMessage: "La contraseña no pudo ser actualizada."});
		} else {
			this.setState({errorMessage: "Contraseña atualizada con éxito.", successRecover: true, errorPass: false, loading: false});
		}
		this.setState({loading: false});
	}

	async verifyToken(token) {
		const req = new Request();
		let data = {
			token: token,
		};
		const apiRequest = await req.post("app/users/validar/recover/token", data);
		if (!apiRequest.error) {
			this.setState({render: "recover", token: token});
		} else {
			this.setState({render: "invalid"});
		}
	}

	renderusuario() {
		return (
			<React.Fragment>
				<div className="white-space-16" />
				<h3 className="text-center color-white">Recuperar contraseña</h3>
				<div className="white-space-16" />
				<form
					className="column full "
					onSubmit={(event) => {
						this.recover(event);
					}}
				>
					<div className="white-space-16" />
					<div className="row full btn-container justify-center">
						<span className="label-login color-white weight-medium">Usuario:</span>
					</div>
					<div className="row full input-container ">
						<div className="column full justify-center align-center">
							<input name="usuario" type="text" maxLength="64" autoComplete="off" placeholder="Usuario" className="weight-semi text-center" required />
						</div>
					</div>
					<div className="white-space-32" />
					<div className="row full btn-container justify-center">
						<button type="submit" className="btn btn-primary color-white">
							{this.state.loading ? (
								<i className="fas fa-spinner fa-spin font-text" />
							) : (
								<span className="color-white">
									<i className="fas fa-sign-in-alt font-text" />
									&nbsp; Recuperar
								</span>
							)}
						</button>
					</div>
					{this.state.errorPass ? (
						<React.Fragment>
							<div className="white-space-16" />
							<div className="column error-login">
								<div className="white-space-8" />
								<p className="text-center color-white">{this.state.errorMessage}</p>
								<div className="white-space-8" />
							</div>
						</React.Fragment>
					) : (
						<div className="white-space-64" />
					)}
					{this.state.successRecover ? (
						<React.Fragment>
							<div className="white-space-16" />
							<div className="column success-login">
								<div className="white-space-8" />
								<p className="text-center color-white">{this.state.errorMessage}</p>
								<div className="white-space-8" />
							</div>
						</React.Fragment>
					) : (
						<div className="white-space-64" />
					)}

					<div className="white-space-64" />
				</form>
			</React.Fragment>
		);
	}

	renderRecover() {
		return (
			<React.Fragment>
				<div className="white-space-16" />
				<h3 className="text-center color-white">Ingrese su nueva contraseña</h3>
				<div className="white-space-16" />
				<form
					className="column full "
					onSubmit={(event) => {
						this.changePasword(event);
					}}
				>
					<div className="white-space-16" />
					<div className="row full btn-container justify-center">
						<span className="label-login color-white weight-medium">Nueva Contraseña:</span>
					</div>
					<div className="row full input-container ">
						<div className="column full justify-center align-center">
							<input name="password" type="text" maxLength="64" autoComplete="off" placeholder="Contraseña" className="weight-semi text-center" required />
						</div>
					</div>
					<div className="white-space-32" />
					<div className="row full btn-container justify-center">
						<button type="submit" className="btn btn-primary color-white">
							{this.state.loading ? (
								<i className="fas fa-spinner fa-spin font-text" />
							) : (
								<span className="color-white">
									<i className="fas fa-sign-in-alt font-text" />
									&nbsp; Cambiar Contraseña
								</span>
							)}
						</button>
					</div>
					{this.state.errorPass ? (
						<React.Fragment>
							<div className="white-space-16" />
							<div className="column error-login">
								<div className="white-space-8" />
								<p className="text-center color-white">{this.state.errorMessage}</p>
								<div className="white-space-8" />
							</div>
						</React.Fragment>
					) : (
						<div className="white-space-64" />
					)}
					{this.state.successRecover ? (
						<React.Fragment>
							<div className="white-space-16" />
							<div className="column success-login">
								<div className="white-space-8" />
								<p className="text-center color-white">{this.state.errorMessage}</p>
								<div className="white-space-8" />
							</div>
						</React.Fragment>
					) : (
						<div className="white-space-64" />
					)}

					<div className="white-space-64" />
				</form>
			</React.Fragment>
		);
	}

	renderInvalid() {
		return (
			<React.Fragment>
				<div className="white-space-16" />
				<h3 className="text-center color-white">Token Invalido</h3>
				<div className="white-space-16" />
				<form
					className="column full "
					onSubmit={(event) => {
						this.recover(event);
					}}
				>
					<div className="white-space-64" />
					<div className="white-space-64" />
				</form>
			</React.Fragment>
		);
	}
	render() {
		return (
			<div className="login column align-center">
				<Helmet>
					<title>ELEVA Capital Group | Recover </title>
				</Helmet>
				<div className="justify-center content">
					<div className="full align-center justify-center">
						<div className="column full colum-space">
							<div className="white-space-8"></div>
						</div>
						<div className="column full justify-center align-center card-login">
							<div className="responsive-img justify-center">
								<Link to="/">
									<img src={Logo} alt={`Logo ELEVA`} title={`Logo ELEVA`} />
								</Link>
							</div>
							{this.state.render === "usuario" ? this.renderusuario() : null}
							{this.state.render === "recover" ? this.renderRecover() : null}
							{this.state.render === "invalid" ? this.renderInvalid() : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(Consumer(Login));

/**
 *  urlApi.js
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO 
 *  @description: Manejor de las ruta directas al API
*/

function url() {

    let url = 'http://localhost:8082/';

    if (process.env.NODE_ENV === 'production') {
        // test
    	// url = 'https://eleva-test.herokuapp.com/';
        //API produccion
    	url = 'https://eleva-cap.herokuapp.com/';
    }
    return url
}

export const URL_API = url();
import React from "react";
import Helmet from "react-helmet";
import {withRouter, Link} from "react-router-dom";

import {Consumer} from "../context";
import Request from "../core/httpClient";
import Logo from "../images/logo.png";

const req = new Request();

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			errorPass: false,
			errorMessage: "",
		};
	}

	async login(e) {
		e.preventDefault();
		this.setState({loading: true});
		const form = e.target;
		const data = {
			usuario: form.usuario.value || "",
			password: form.password.value || "",
		};

		const res = await req.post("users/login", data);
		if (res.error) {
			this.setState({errorPass: true, errorMessage: "Usuario o contraseña incorrecta"});
		} else {
			if (res.token) {
				this.props.context.loginSuccess({auth: true, token: res.token});
				this.props.context.getUser();
				this.props.history.push("/");
			} else {
				this.setState({message: res.message, loading: false});
			}
		}
		this.setState({loading: false});
	}

	render() {
		return (
			<div className="login column align-center">
				<Helmet>
					<title>ELEVA Capital Group | Login </title>
				</Helmet>
				<div className="justify-center content">
					<div className="full align-center justify-center">
						<div className="column full colum-space">
							<div className="white-space-8"></div>
						</div>
						<div className="column full justify-center align-center card-login">
							<div className="responsive-img justify-center">
								<img src={Logo} alt={`Logo ELEVA`} title={`Logo ELEVA`} />
							</div>
							<div className="white-space-16" />
							<h3 className="text-center color-white">Iniciar sesión</h3>
							<div className="white-space-16" />
							<form
								className="column full "
								onSubmit={(event) => {
									this.login(event);
								}}
							>
								<div className="white-space-16" />
								<div className="row full btn-container justify-center">
									<span className="label-login color-white weight-medium">Usuario:</span>
								</div>
								<div className="row full input-container ">
									<div className="column full justify-center align-center">
										<input name="usuario" type="text" maxLength="64" autoComplete="off" placeholder="Usuario" className="weight-semi text-center" required />
									</div>
								</div>

								<div className="white-space-16" />
								<div className="row full btn-container justify-center">
									<span className="label-login color-white weight-medium">Contraseña:</span>
								</div>
								<div className="row full input-container ">
									<div className="column full justify-center align-center">
										<input name="password" type="password" maxLength="64" autoComplete="off" placeholder="Contraseña" className="weight-semi text-center" required />
									</div>
								</div>

								<div className="white-space-8" />

								<div className="white-space-32" />
								<div className="row full btn-container justify-center">
									<button type="submit" className="btn btn-primary color-white">
										{this.state.loading ? (
											<i className="fas fa-spinner fa-spin font-text" />
										) : (
											<span className="color-white">
												<i className="fas fa-sign-in-alt font-text" />
												&nbsp; ACCEDER
											</span>
										)}
									</button>
								</div>
								<div className="white-space-16"></div>
								<div className="row full justify-center align-center">
									<Link to="/recover" className="color-white">Recuperar Contraseña</Link>
								</div>
								{this.state.errorPass ? (
									<React.Fragment>
										<div className="white-space-16" />
										<div className="column error-login">
											<div className="white-space-8" />
											<p className="text-center color-white">{this.state.errorMessage}</p>
											<div className="white-space-8" />
										</div>
									</React.Fragment>
								) : null}
								<div className="white-space-64" />
								<div className="white-space-64" />
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(Consumer(Login));

import React, {useState, useEffect, useRef} from "react";
import moment from "moment-timezone";
import ImageLoader from "react-loading-image";
import Rating from "react-rating";

import Paginator from "../../Paginator";
import ModalZoom from "../../modal/modalZoom";
import NoAvalible from "../../../images/no-image-available.png";
import Loading from "../../../images/load.gif";
import {Link} from "react-router-dom";

const ModalSolicitud = (props) => {
	const [solicitud, setSolicitud] = useState({});
	const [problemas, setProblemas] = useState([]);
	const [areas, setAreas] = useState([]);
	const [equipos, setEquipos] = useState([]);
	const [estados, setEstados] = useState([]);
	const [proyectos, setProyectos] = useState([]);
	const [visitas, setVisitas] = useState([]);
	const [visita, setVisita] = useState({});
	const [unidades, setUnidades] = useState([]);
	const [propietarios, setPropietarios] = useState([]);
	const [newMode, setNewMode] = useState(false);
	const [loadingVisitas, setLoadingVisitas] = useState(false);
	const [savingVisitas, setSavingVisitas] = useState(false);
	const [imgZoom, setImgZoom] = useState("");
	const [page, setPage] = useState(1); // pagina

	useEffect(() => {
		setSolicitud(props.solicitud);
		setProblemas(props.problemas);
		setAreas(props.areas);
		setEquipos(props.equipos);
		setEstados(props.estados);
		setProyectos(props.proyectos);
		setUnidades(props.unidades);
		setPropietarios(props.propietarios);
		setNewMode(props.newMode);
	}, [props.solicitud, props.problemas, props.areas, props.equipos, props.estados, props.proyectos, props.unidades, props.newMode, props.propietarios]);

	useEffect(() => {
		setVisita(props.visita);
		setVisitas(props.visitas);
		setLoadingVisitas(props.loadingVisitas);
		setSavingVisitas(props.savingVisitas);
	}, [props.loadingVisitas, props.visitas, props.visita, props.savingVisitas]);

	function updateSolicitud(slc) {
		props.updateJson({solicitud: slc});
	}

	function updateVisita(vst) {
		props.updateJson({visita: vst});
		setVisita(vst);
	}

	async function selectPage(page) {
		setPage(page);
	}

	const hiddenFileInput = useRef(null);
	const hiddenFileInput2 = useRef(null);
	const hiddenFileInput3 = useRef(null);

	function openZoom(img) {
		setImgZoom(img);
		let modal = document.getElementById("image-zoom-modal");
		if (modal) {
			modal.classList.remove("hide-zoom-modal");
			modal.classList.add("show-zoom-modal");
		}
	}

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	async function changeImg(idImg, event) {
		let file = event.target;
		if (file) {
			if (file.files[0]) {
				let sol = solicitud;
				if (idImg === 1) {
					sol.Img1 = await toBase64(file.files[0]);
					sol.File1 = file.files[0];
				} else if (idImg === 2) {
					sol.Img2 = await toBase64(file.files[0]);
					sol.File2 = file.files[0];
				} else if (idImg === 3) {
					sol.Img3 = await toBase64(file.files[0]);
					sol.File3 = file.files[0];
				} else if (idImg === 4) {
					sol.ImgEvidencia1 = await toBase64(file.files[0]);
					sol.ImgEvidencia1 = file.files[0];
				} else if (idImg === 5) {
					sol.ImgEvidencia2 = await toBase64(file.files[0]);
					sol.ImgEvidencia2 = file.files[0];
				} else if (idImg === 6) {
					sol.ImgEvidencia3 = await toBase64(file.files[0]);
					sol.ImgEvidencia3 = file.files[0];
				}
				await updateSolicitud(sol);
			}
		}
	}

	return (
		<React.Fragment>
			<div className="worko-tabs">
				<input className="state" type="radio" title="tab-one" name="tabs-state" id="tab-one" defaultChecked />
				<input className="state" type="radio" title="tab-two" name="tabs-state" id="tab-two" />
				<input className="state" type="radio" title="tab-three" name="tabs-state" id="tab-three" />
				<input className="state" type="radio" title="tab-four" name="tabs-state" id="tab-four" />
				<input className="state" type="radio" title="tab-five" name="tabs-state" id="tab-five" />

				<div className="tabs flex-tabs">
					<label htmlFor="tab-one" id="tab-one-label" className="tab">
						General
					</label>
					<label htmlFor="tab-two" id="tab-two-label" className="tab">
						Garantía
					</label>
					<label htmlFor="tab-three" id="tab-three-label" className="tab">
						Visitas
					</label>
					<label htmlFor="tab-five" id="tab-five-label" className="tab">
						Realizado
					</label>
					<label htmlFor="tab-four" id="tab-four-label" className="tab">
						Valoración
					</label>
					<div id="tab-one-panel" className="panel active">
						<div className="full row align-center">
							<div className="column full">
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>No Solicitud:</b>
										</p>
									</div>
									<div className="column column-small">
										<input
											type="text"
											className="input input-modals"
											value={solicitud.NoSolicitud ? solicitud.NoSolicitud : ""}
											autoComplete="off"
											disabled
											minLength={2}
											required
										/>
									</div>
									<div className="column label-middle">
										<p>
											<b>Fecha:</b>
										</p>
									</div>
									<div className="column column-small">
										<input
											type="date"
											className="input input-modals"
											required
											autoComplete="off"
											disabled={!newMode}
											value={solicitud.Fecha ? moment(solicitud.Fecha).utc().format("YYYY-MM-DD") : ""}
											onChange={(event) => {
												let sol = solicitud;
												sol.Fecha = event.target.value;
												updateSolicitud(sol);
											}}
										/>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Proyecto:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdProyecto ? solicitud.IdProyecto : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdProyecto = event.target.value;
												sol.IdPersona = "";
												sol.IdUnidad = "";
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Proyecto</option>
											{proyectos.map((pry, key) => (
												<option key={key} value={pry.IdProyecto}>
													{pry.Nombre}
												</option>
											))}
										</select>
									</div>
									<div className="column label-middle">
										<p>
											<b>Unidad:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdUnidad ? solicitud.IdUnidad : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdPersona = "";
												sol.IdUnidad = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Elige Unidad</option>
											{unidades
												.filter((un) => (solicitud.IdProyecto ? solicitud.IdProyecto == un.IdProyecto : true))
												.map((unidad, key) => (
													<option key={key} value={unidad.IdUnidad}>
														{unidad.Numero}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Reportó:</b>
										</p>
									</div>
									<div className="column full">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdPersona ? solicitud.IdPersona : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdPersona = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Nombre</option>
											{propietarios
												.filter((pr) => (solicitud.IdUnidad ? solicitud.IdUnidad == pr.IdUnidad : false))
												.map((pr, key) => (
													<option key={key} value={pr.IdPersona}>
														{pr.Nombre}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Área:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdArea ? solicitud.IdArea : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdArea = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Área</option>
											{areas.map((area, key) => (
												<option key={key} value={area.IdArea}>
													{area.NombreArea}
												</option>
											))}
										</select>
									</div>
									<div className="column label-middle">
										<p>
											<b>Equipo:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											required
											value={solicitud.IdEquipo ? solicitud.IdEquipo : ""}
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdEquipo = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Equipo</option>
											{equipos.map((equipo, key) => (
												<option key={key} value={equipo.IdEquipo}>
													{equipo.NombreEquipo}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Detalle:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdProblema ? solicitud.IdProblema : ""}
											required
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.IdProblema = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Detalle</option>
											{problemas.map((problema, key) => (
												<option key={key} value={problema.IdProblema}>
													{problema.NombreProblema}
												</option>
											))}
										</select>
									</div>
									<div className="column label-middle">
										<p>
											<b>Fecha visita:</b>
										</p>
									</div>
									<div className="column column-small">
										<input className="input" type="datetime-local" disabled value={solicitud.FechaVisita ? moment(solicitud.FechaVisita).format("YYYY-MM-DDTHH:mm:ss") : ""} />
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Comentarios Inquilino:</b>
										</p>
									</div>
									<div className="column full">
										<textarea
											className="input"
											value={solicitud.Comentarios ? solicitud.Comentarios : ""}
											disabled={!newMode}
											onChange={(event) => {
												let sol = solicitud;
												sol.Comentarios = event.target.value;
												updateSolicitud(sol);
											}}
										></textarea>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column full column-image justify-center align-center img-update">
										<div className="container-img justify-center align-center">
											<ImageLoader
												src={solicitud.Img1}
												image={() => <img src={solicitud.Img1} alt="Producto" />}
												loading={() => <img src={Loading} alt="Cargando..." />}
												error={() => <img src={NoAvalible} alt="Cargando..." />}
											/>
											<div className="middle-overlay">
												<label className="btn-upload-image column align-center justify-center">
													<Link
														to="#"
														onClick={() => {
															openZoom(solicitud.Img1);
														}}
													>
														<i className="fas fa-eye"> &nbsp; Ver</i>
													</Link>
													{!solicitud.IdSolicitud ? (
														<React.Fragment>
															<div className="white-space-8"></div>

															<Link
																to="#"
																onClick={() => {
																	hiddenFileInput.current.click();
																}}
															>
																<i className="fas fa-file-upload"> &nbsp; Subir </i>
															</Link>
															<input type="file" ref={hiddenFileInput} id="img1" accept="image/*" onChange={changeImg.bind(this, 1)} />
														</React.Fragment>
													) : null}
												</label>
											</div>
										</div>
									</div>
									<div className="column full column-image justify-center align-center img-update">
										<div className="container-img justify-center align-center">
											<ImageLoader
												src={solicitud.Img2}
												image={() => <img src={solicitud.Img2} alt="Producto" />}
												loading={() => <img src={Loading} alt="Cargando..." />}
												error={() => <img src={NoAvalible} alt="Cargando..." />}
											/>
											<div className="middle-overlay">
												<label className="btn-upload-image column align-center justify-center">
													<Link
														to="#"
														onClick={() => {
															openZoom(solicitud.Img2);
														}}
													>
														<i className="fas fa-eye"> &nbsp; Ver</i>
													</Link>
													{!solicitud.IdSolicitud ? (
														<React.Fragment>
															<div className="white-space-8"></div>

															<Link
																to="#"
																onClick={() => {
																	hiddenFileInput2.current.click();
																}}
															>
																<i className="fas fa-file-upload"> &nbsp; Subir </i>
															</Link>
															<input type="file" ref={hiddenFileInput2} id="img2" accept="image/*" onChange={changeImg.bind(this, 2)} />
														</React.Fragment>
													) : null}
												</label>
											</div>
										</div>
									</div>
									<div className="column full column-image justify-center align-center img-update">
										<div className="container-img justify-center align-center">
											<ImageLoader
												src={solicitud.Img3}
												image={() => <img src={solicitud.Img3} alt="Producto" />}
												loading={() => <img src={Loading} alt="Cargando..." />}
												error={() => <img src={NoAvalible} alt="Cargando..." />}
											/>
											<div className="middle-overlay">
												<label className="btn-upload-image column align-center justify-center">
													<Link
														to="#"
														onClick={() => {
															openZoom(solicitud.Img3);
														}}
													>
														<i className="fas fa-eye"> &nbsp; Ver</i>
													</Link>
													{!solicitud.IdSolicitud ? (
														<React.Fragment>
															<div className="white-space-8"></div>

															<Link
																to="#"
																onClick={() => {
																	hiddenFileInput3.current.click();
																}}
															>
																<i className="fas fa-file-upload"> &nbsp; Subir </i>
															</Link>
															<input type="file" ref={hiddenFileInput3} id="img3" accept="image/*" onChange={changeImg.bind(this, 3)} />
														</React.Fragment>
													) : null}
												</label>
											</div>
										</div>
									</div>
								</div>
								<div className="white-space-8" />
							</div>
						</div>
					</div>
					<div id="tab-two-panel" className="panel">
						<div className="full row align-center">
							<div className="column full">
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Aplica Garantía:</b>
										</p>
									</div>
									<div className="column column-small">
										<label className="container-check">
											<input
												type="checkbox"
												checked={solicitud.AplicaGarantia ? solicitud.AplicaGarantia : false}
												onClick={() => {
													let sol = solicitud;
													sol.AplicaGarantia = !sol.AplicaGarantia;
													updateSolicitud(sol);
												}}
												disabled
											/>
											<span className="checkmark"></span>
										</label>
									</div>
									<div className="column label-middle">
										<p>
											<b>Estado Solicitud:</b>
										</p>
									</div>
									<div className="column column-small">
										<select
											name=""
											className="input input-modals"
											value={solicitud.IdEstado ? solicitud.IdEstado : ""}
											required
											disabled
											onChange={(event) => {
												let sol = solicitud;
												sol.IdEstado = event.target.value;
												updateSolicitud(sol);
											}}
										>
											<option value="">Sin Estado</option>
											{estados.map((edo, key) => (
												<option key={key} value={edo.IdEstado}>
													{edo.Estado}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Comentario:</b>
										</p>
									</div>
									<div className="column full">
										<textarea
											className="input"
											value={solicitud.Comentario ? solicitud.Comentario : ""}
											disabled
											onChange={(event) => {
												let sol = solicitud;
												sol.Comentario = event.target.value;
												updateSolicitud(sol);
											}}
										></textarea>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Comentario Visita:</b>
										</p>
									</div>
									<div className="column full">
										<textarea
											className="input"
											value={solicitud.ComentariosAplica ? solicitud.ComentariosAplica : ""}
											disabled
											onChange={(event) => {
												let sol = solicitud;
												sol.ComentariosAplica = event.target.value;
												updateSolicitud(sol);
											}}
										></textarea>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center justify-center">
									<div className="column-middle label-normal justify-center">
										<p>
											<b>Fecha 1:</b>
										</p>
									</div>
									<div className="column-middle label-normal  justify-center">
										<p>
											<b>Fecha 2:</b>
										</p>
									</div>
									<div className="column-middle label-normal  justify-center">
										<p>
											<b>Fecha 3:</b>
										</p>
									</div>
								</div>
								<div className="row full align-center justify-center">
									<div className="column-middle justify-center">
										<input
											className="input input-huge"
											type="datetime-local"
											value={solicitud.Fecha1 ? moment(solicitud.Fecha1).format("YYYY-MM-DDTHH:mm:ss") : ""}
											disabled
											required
											onChange={(event) => {
												let sol = solicitud;
												sol.Fecha1 = event.target.value;
												updateSolicitud(sol);
											}}
										/>
									</div>
									<div className="column-middle justify-center">
										<input
											className="input input-huge"
											type="datetime-local"
											disabled
											value={solicitud.Fecha2 ? moment(solicitud.Fecha2).format("YYYY-MM-DDTHH:mm:ss") : ""}
											onChange={(event) => {
												let sol = solicitud;
												sol.Fecha2 = event.target.value;
												updateSolicitud(sol);
											}}
										/>
									</div>
									<div className="column-middle justify-center">
										<input
											className="input input-huge"
											type="datetime-local"
											disabled
											value={solicitud.Fecha3 ? moment(solicitud.Fecha3).format("YYYY-MM-DDTHH:mm:ss") : ""}
											onChange={(event) => {
												let sol = solicitud;
												sol.Fecha3 = event.target.value;
												updateSolicitud(sol);
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div id="tab-three-panel" className="panel">
						<div className="full row align-center">
							<div className="column full">
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Responsable:</b>
										</p>
									</div>
									<div className="column column-small">
										<input
											type="text"
											className="input input-modals"
											value={visita.Responsable ? visita.Responsable : ""}
											autoComplete="off"
											onChange={(event) => {
												let visit = visita;
												visit.Responsable = event.target.value;
												updateVisita(visit);
											}}
										/>
									</div>
									<div className="column label-middle">
										<p>
											<b>Fecha:</b>
										</p>
									</div>
									<div className="column column-small">
										<input
											className="input input-modals"
											type="datetime-local"
											value={visita.Fecha ? visita.Fecha : ""}
											autoComplete="off"
											onChange={(event) => {
												let visit = visita;
												visit.Fecha = event.target.value;
												updateVisita(visit);
											}}
										/>
									</div>
								</div>
								<div className="white-space-8"></div>
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Comentario:</b>
										</p>
									</div>
									<div className="column full">
										<textarea
											className="input"
											value={visita.Comentario ? visita.Comentario : ""}
											onChange={(event) => {
												let visit = visita;
												visit.Comentario = event.target.value;
												updateVisita(visit);
											}}
										></textarea>
									</div>
								</div>
								<div className="white-space-16"></div>
								<div className="row full align-center justify-end">
									<div className="column label-normal">
										<button
											className="btn btn-secondary btn-small color-white"
											disabled={savingVisitas}
											onClick={(event) => {
												props.saveVisitas(event);
											}}
										>
											{savingVisitas ? <i className="fas fa-spinner fa-spin"></i> : "Agregar"}
										</button>
									</div>
								</div>
								<div className="white-space-8"></div>
								<div className="row full align-center">
									<div className="column card-table full align-center card-table-modal">
										<div className="table-responsive full">
											<table>
												<tr>
													<th className="text-left">Responsable</th>
													<th className="text-center">Fecha</th>
													<th className="text-left">Comentario</th>
												</tr>
												<tbody>
													{loadingVisitas ? (
														<tr>
															<td className="text-center" colSpan="3">
																<i className="fas fa-spinner fa-spin"></i>
															</td>
														</tr>
													) : (visitas ? visitas.length : 0) == 0 ? (
														<tr>
															<td className="text-center" colSpan="3">
																Sin datos que mostrar
															</td>
														</tr>
													) : (
														visitas.slice((page - 1) * 8, page * 8).map((vsit, key) => (
															<tr key={key}>
																<td className="text-left">{vsit.Responsable}</td>
																<td className="text-center">{vsit.Fecha ? moment(vsit.Fecha).format("DD-MM-YYYY HH:mm") : ""}</td>
																<td>{vsit.Comentario}</td>
															</tr>
														))
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="row full justify-center">
									<Paginator pages={Math.ceil(visitas.length / 8)} setPage={selectPage.bind(this)} />
								</div>
							</div>
						</div>
					</div>
					<div id="tab-four-panel" className="panel">
						<div className="full row align-center">
							<div className="column full">
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Reparado:</b>
										</p>
									</div>
									<div className="column full justify-center align-center">
										<label className="container-check">
											<input type="checkbox" checked={solicitud.Reparado ? solicitud.Reparado : false} disabled readOnly />
											<span className="checkmark"></span>
										</label>
									</div>
								</div>
								<div className="white-space-8" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Comentario:</b>
										</p>
									</div>
									<div className="column full">
										<textarea className="input" value={solicitud.ComentarioReparacion ? solicitud.ComentarioReparacion : ""} disabled></textarea>
									</div>
								</div>
								<div className="white-space-16" />
								<div className="row full align-center">
									<div className="column label-normal">
										<p>
											<b>Valoración:</b>
										</p>
									</div>
									<div className="column full justify-center align-center">
										<Rating
											stop={5}
											initialRating={solicitud.Valoracion ? solicitud.Valoracion : 0}
											readonly
											emptySymbol="far fa-star fa-2x"
											fullSymbol="fas fa-star fa-2x color-primary"
										/>
									</div>
								</div>
								<div className="white-space-8" />
							</div>
						</div>
					</div>
					<div id="tab-five-panel" className="panel">
					{solicitud.IdEstado >= 7 &&
						<div className="full column align-center">
							<div className="row full align-center">
								<div className="column label-normal">
									<p>
										<b>Respuesta del cliente, si el trabajo fue realizado:</b>
										<strong> {solicitud.RespuestaRealizado == 'true'? 'SI' : solicitud.RespuestaRealizado == 'false'? 'NO' : null} </strong>
									</p>
								</div>
							</div>
							<div className="row full">
								<div className="column full column-image justify-center align-center img-update">
									<div className="container-img justify-center align-center">
										<ImageLoader
											src={solicitud.ImgEvidencia1}
											image={() => <img src={solicitud.ImgEvidencia1} alt="Evidencia" />}
											loading={() => <img src={Loading} alt="Cargando..." />}
											error={() => <img src={NoAvalible} alt="Cargando..." />}
										/>
										<div className="middle-overlay">
											<label className="btn-upload-image column align-center justify-center">
												<Link
													to="#"
													onClick={() => {
														openZoom(solicitud.ImgEvidencia1);
													}}
												>
													<i className="fas fa-eye"> &nbsp; Ver</i>
												</Link>
											</label>
										</div>
									</div>
								</div>
								<div className="column full column-image justify-center align-center img-update">
									<div className="container-img justify-center align-center">
										<ImageLoader
											src={solicitud.ImgEvidencia2}
											image={() => <img src={solicitud.ImgEvidencia2} alt="Evidencia" />}
											loading={() => <img src={Loading} alt="Cargando..." />}
											error={() => <img src={NoAvalible} alt="Cargando..." />}
										/>
										<div className="middle-overlay">
											<label className="btn-upload-image column align-center justify-center">
												<Link
													to="#"
													onClick={() => {
														openZoom(solicitud.ImgEvidencia2);
													}}
												>
													<i className="fas fa-eye"> &nbsp; Ver</i>
												</Link>
											</label>
										</div>
									</div>
								</div>
								<div className="column full column-image justify-center align-center img-update">
									<div className="container-img justify-center align-center">
										<ImageLoader
											src={solicitud.ImgEvidencia3}
											image={() => <img src={solicitud.ImgEvidencia3} alt="Evidencia" />}
											loading={() => <img src={Loading} alt="Cargando..." />}
											error={() => <img src={NoAvalible} alt="Cargando..." />}
										/>
										<div className="middle-overlay">
											<label className="btn-upload-image column align-center justify-center">
												<Link
													to="#"
													onClick={() => {
														openZoom(solicitud.ImgEvidencia3);
													}}
												>
													<i className="fas fa-eye"> &nbsp; Ver</i>
												</Link>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
					</div>
				</div>
			</div>

			<ModalZoom
				imgZoom={imgZoom}
				closeModal={(value) => {
					setImgZoom(value);
				}}
			/>
		</React.Fragment>
	);
};
export default ModalSolicitud;

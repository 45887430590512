import React, {useState, useEffect} from "react";

const ModalFolder = (props) => {
	const [folder, setFolder] = useState({});

	useEffect(() => {
		setFolder(props.folder);
	}, [props.folder]);

	function updateFolder(fld) {
		props.updateJson({folder: fld});
	}

	return (
		<div className="full row align-center">
			<div className="column full">
				<div className="white-space-16" />
				<div className="row full align-center">
					<div className="column label-normal">
						<p>
							<b>Nombre:</b>
						</p>
					</div>
					<div className="column full">
						<input
							className="input"
							value={folder.NombreFolder ? folder.NombreFolder : ""}
							onChange={(event) => {
								let fld = folder;
								fld.NombreFolder = event.target.value;
								updateFolder(fld);
							}}
						></input>
					</div>
				</div>
				<div className="white-space-8" />
			</div>
		</div>
	);
};
export default ModalFolder;

import React from "react";
import {Consumer} from "../context";

const TableDesarrollo = (props) => {
	return (
		<div className="container">
			<div className="card-table full">
				<div className="table-responsive full">
					<table>
						<thead>
							<tr>
								<th className="text-left">Desarollo</th>
								<th className="text-left ">Ubicación</th>
								<th className="text-left th-lg">Unidades</th>
								<th className="text-left th-lg">Pendientes</th>
								<th className="text-left th-lg">Activo</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1</td>
								<td>2</td>
								<td>3</td>
								<td>4</td>
								<td>5</td>
							</tr>
							<tr>
								<td>1</td>
								<td>2</td>
								<td>3</td>
								<td>4</td>
								<td>5</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default Consumer(TableDesarrollo);
